/**
 *  This file contains the constants used in this application
*/


export const MODE = "PROD"; // DEV or PROD are acceptable values


export const DEV_BASE_URL = "https://backend-vendor-test.elfrique.com/api/v1";
export const PROD_BASE_URL = "https://backend-vendor.elfrique.com/api/v1";
export const DEV_BASE_PUBLIC_URL = "https://backend-vendor-test.elfrique.com/public";
export const PROD_BASE_PUBLIC_URL = "https://backend-vendor.elfrique.com/public";