import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as TestimonialActions from '../../redux/actions/testimonials';
import FormValidator from "./../../utils/FormValidator";
import Empty from '../../components/Empty';
import Loading from '../../components/Loading';


class Testimonials extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            { field: "name", method: "isEmpty", validWhen: false, message: "Name cannot be empty." },
            { field: "content", method: "isEmpty", validWhen: false, message: "Content cannot be empty." }
        ]);

        this.state = {
            testimonial: {
                name: "",
                content: ""
            },
            validation: this.validator.reset(),
            selectedTestimonial: null
        }
    }

    componentDidMount() {
        this.props.dispatch(TestimonialActions.fetchAllTestimonials());
    }

    handleChange = (propertyName, e) => {
        const testimonial = this.state.testimonial;
        testimonial[propertyName] = e.target.value;
        this.setState({ testimonial });
    }

    handleUpdateChange = (propertyName, e) => {
        const selectedPlan = this.state.selectedPlan;
        selectedPlan[propertyName] = e.target.value;
        this.setState({ selectedPlan });
    }

    handleSelection = (testimonial) => {
        this.setState({ selectedTestimonial: testimonial })
    }

    addTestimonial = (e) => {
        e.preventDefault();
        const { name, content } = this.state.testimonial;
        const payload = { name, content };

        const formData = new FormData();
        formData.append('name', name);
        formData.append('message', content);

        const validation = this.validator.validate(payload);

        if (validation.isValid) {
            this.props.dispatch(TestimonialActions.createTestimonials(formData));
            this.setState({ validation });
        } else {
            this.setState({ validation });
        }
    }

    updateTestimonial = (e) => {
        e.preventDefault();
        const { id, name, content } = this.state.selectedTestimonial;
        const payload = { name, content };

        const formData = new FormData();
        formData.append('id', id);
        formData.append('name', name);
        formData.append('message', content);

        const validation = this.validator.validate(payload);

        if (validation.isValid) {
            this.props.dispatch(TestimonialActions.updateTestimonial(formData));
            this.setState({ validation });
        } else {
            this.setState({ validation });
        }
    }

    deleteTestimonial = (id, e) => {
        e.preventDefault();
        this.props.dispatch(TestimonialActions.deleteTestimonial(id));
    }

    approveTestimonial = (id, e) => {
        e.preventDefault();
        const payload = new FormData();
        payload.append('id', id);
        this.props.dispatch(TestimonialActions.approveTestimonial(payload));
    }

    disapproveTestimonial = (id, e) => {
        e.preventDefault();
        const payload = new FormData();
        payload.append('id', id);
        this.props.dispatch(TestimonialActions.disapproveTestimonial(payload));
    }

    render() {
        const { validation } = this.state;
        const { testimonials: { testimonials, testimonialsLoading, createLoading } } = this.props;
        return (
            <article className="top-background result w-100 h-100">
                <div className="container py-3 p-md-3">
                    <div className="row w-100 mx-0">
                        <section className="main-body p-5 col-12">
                            <div className="col-md-12 priceall">
                                <div className="row no-margin">
                                    <div className="col-md-12 ">
                                        <div className="row justify-content-between no-margin">
                                            <h3 className="text-centers px-md-5s mb-3"><b>Manage Customer's Testimonials</b></h3>
                                            <button className="btn-subscription" data-toggle="modal" data-target="#addTestimonialPlan">Add Testimonial</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row w-100 mx-0 mt-4">
                                    {
                                        testimonialsLoading
                                            ? <div className="col-md-12 text-center">
                                                <Loading />
                                            </div>
                                            : testimonials && testimonials.length > 0 ? testimonials.map(({message, status, name, id}, i) => {
                                                return (
                                                    <div className="text-center col-12 col-lg-4 mb-4">
                                                        <div className="pricesbx">
                                                            <h5 className="w-100 p-3 rounded">{name}</h5>
                                                            <div className="py-4 px-3 rounded">
                                                                <p>{message}</p>
                                                                {
                                                                    status === 0
                                                                    ? <a href="#" className="button mr-2" data-toggle="modal" data-target="#editSubscriptionPlan" onClick={this.approveTestimonial.bind(this, id)}>Approve</a>
                                                                    : <a href="#" className="button delete" onClick={this.disapproveTestimonial.bind(this, id)}>Disapprove</a>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                                : <Empty
                                                    title="Empty Testimonials!"
                                                    subText="Sorry, there are no testimonies from anyone yet" />
                                    }
                                </div>
                            </div>
                        </section>
                        <div className="modal fade" id="addTestimonialPlan" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalCenterTitle">Add Testimonials</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div className="modal-body text-center">
                                        <form>
                                            <div className="form-group text-left">
                                                <label>Full Name</label>
                                                <input type="text" className="form-control" onChange={this.handleChange.bind(this, "name")} />
                                                {validation.name.isValid ? '' : <span className="error-message text-left">{validation.name.message}</span>}
                                            </div>
                                            <div className="form-group text-left">
                                                <label>Content</label>
                                                <textarea className="form-control" onChange={this.handleChange.bind(this, "content")} />
                                                {validation.content.isValid ? '' : <span className="error-message text-left">{validation.content.message}</span>}
                                            </div>
                                        </form>
                                    </div>
                                    <div className="modal-footer text-center">
                                        <button type="button" className="btn btn-secondary btn-cancel" data-dismiss="modal">Cancel</button>
                                        {
                                            createLoading
                                                ? <button type="button" className="btn btn-primary btn-success">Adding...</button>
                                                : <button type="button" className="btn btn-primary btn-success" onClick={this.addTestimonial}>Add Plan</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article>
        )
    }
}

export default withRouter(
    connect(state => ({
        testimonials: state.testimonials
    }))(Testimonials)
);