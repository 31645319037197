import React from 'react';

const ManagePlans = () => {
    return (
        <article className="top-background result w-100 h-100">

			<div className="container py-3 p-md-3">

				<div className="row w-100 mx-0">

					<section className="main-body p-5 col-12">
						<div className="priceall">
							<h3 className="text-center px-md-5">Free sounds great but why not get more value from our plans</h3>
							<p className="text-center graytxt px-md-5 mb-4">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</p>
							<div className="row w-100 mx-0">
								<div className="pricesbx text-center col-12 col-lg-4">
									<h5 className="w-100 p-3 rounded">Free Plan - NGN 0</h5>
									<div className="py-4 px-3 rounded shadow">
										<p>1 Enquiry Guaranteed</p>	 	
										<p>Google Page SEO enhanced</p>
										<a href="#" className="button">List a service</a>									
									</div>
								</div>
								<div className="pricesbx text-center col-12 col-lg-4">
									<h5 className="w-100 p-3 rounded">Basic Plan - NGN 15,600</h5>
									<div className="py-4 px-3 rounded shadow">
										<p>5 Enquiries Guaranteed</p>	
										<p>Google Page SEO enhanced</p>
										<p>Bidding</p>
										<p>Contact Display</p>
										<a href="#" className="button">List a service</a>									
									</div>
								</div>
								<div className="pricesbx text-center col-12 col-lg-4">
									<h5 className="w-100 p-3 rounded">Exclusive Plan - NGN 29,800</h5>
									<div className="py-4 px-3 rounded shadow">
										<p>12 Enquiries Guaranteed</p>	
										<p>Google Page SEO enhanced</p>
										<p>Bidding</p>
										<p>Contact Display</p>
										<p>Elfrique Home Page Display</p>
										<p>Elfrique Slider Display</p>
										<a href="#" className="button">List a service</a>									
									</div>
								</div>
							</div>
							<p className="text-center graytxt px-md-5 mt-4">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</p>
						</div>

					</section>

				</div>
			
			</div>	
		
		</article>
    )
}

export default ManagePlans;