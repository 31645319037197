import axios from 'axios';
import Swal from 'sweetalert2';
import { MODE, PROD_BASE_URL, DEV_BASE_URL } from './../../constants';


const base_url = MODE === "PROD" ? PROD_BASE_URL : DEV_BASE_URL;
const accessToken = window.localStorage.getItem('accessToken');
const configAuth = {
    headers: {
        'Accept': 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
    }
};
const authAxios = axios.create({
    ...configAuth,
    baseURL: base_url
});

export const fetchNotifications = () => {
    return dispatch => {
        dispatch({
            type: 'FETCH_NOTIFICATIONS_PENDING'
        })
        return authAxios.get('/get-all-notifications')
            .then(res => {
                const { data, status, error } = res.data
                if (status === 1)
                    dispatch({
                        type: 'FETCH_NOTIFICATIONS_SUCCESS',
                        data
                    })
                else {
                    dispatch({
                        type: 'FETCH_NOTIFICATIONS_ERROR',
                        error
                    })
                    Swal.fire('An error occured', `Failed to load notifications: ${error}`, 'error')

                }
            })
            .catch(error => {
                if (process.env.NODE_ENV === "development")
                    console.warn(error)
                dispatch({
                    type: 'FETCH_NOTIFICATIONS_ERROR',
                    error: error.message
                })
                Swal.fire('An error occured', `Failed to load notifications: ${error.message}`, 'error')
            })
    }
}

export const markNotificationAsRead = ({ notificationId }) => {
    return (dispatch, getState) => {
        const state = getState().notifications
        if (state.optimisticallyMarked.some(
            notification => notification.id === notificationId
        ))
            return
        dispatch({
            type: 'MARK_NOTIFICATION_SEEN_OPTIMISTIC',
            notificationId
        })
        return authAxios.get(`/mark-as-seen/${notificationId}`)
            .then(res => {
                const { status, error } = res.data;
                if (status === 1)
                    dispatch({
                        type: 'MARK_NOTIFICATION_SEEN_SUCCESS',
                        notificationId
                    })
                else {
                    dispatch({
                        type: 'MARK_NOTIFICATION_SEEN_ERROR',
                        error,
                        notificationId
                    })
                    Swal.fire('An error occured', `Failed to mark as seen: ${error}`, 'error')
                }
            })
            .catch(error => {
                if (process.env.NODE_ENV === "development")
                    console.warn(error)
                dispatch({
                    type: 'MARK_NOTIFICATION_SEEN_ERROR',
                    error: error.message,
                    notificationId
                })
                Swal.fire('An error occured', `Failed to mark as seen: ${error.message}`, 'error')
            })
    }
}