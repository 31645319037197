const initialState = {
    testimonials: [],
    error: null,
    loading: false,
    testimonialsLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_TESTIMONIAL_PENDING':
            return { ...state, createLoading: action.loading };
        case 'CREATE_TESTIMONIAL_SUCCESS':
            return { ...state, createLoading: action.loading };
        case 'CREATE_TESTIMONIAL_FAILED':
            return { ...state, createLoading: action.loading, error: action.error };

        case 'UPDATE_TESTIMONIAL_PENDING':
            return { ...state, updateLoading: action.loading };
        case 'UPDATE_TESTIMONIAL_SUCCESS':
            return { ...state, updateLoading: action.loading };
        case 'UPDATE_TESTIMONIAL_FAILED':
            return { ...state, updateLoading: action.loading, error: action.error };

        case 'DELETE_TESTIMONIAL_PENDING':
            return { ...state, deleteLoading: action.loading };
        case 'DELETE_TESTIMONIAL_SUCCESS':
            return { ...state, deleteLoading: action.loading };
        case 'DELETE_TESTIMONIAL_FAILED':
            return { ...state, deleteLoading: action.loading, error: action.error };

        case 'FETCH_ALL_TESTIMONIALS_PENDING':
            return { ...state, testimonialsLoading: action.loading };
        case 'FETCH_ALL_TESTIMONIALS_SUCCESS':
            return { ...state, testimonials: action.testimonials, testimonialsLoading: action.loading };
        case 'FETCH_ALL_TESTIMONIALS_FAILED':
            return { ...state, testimonialsLoading: action.loading, error: action.error };
        default:
            return state;
    }
};