import React, { Component } from 'react';
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";

// import styles from './../styles/dashboard.scss';
import Dashboard from '../containers/private/Dashboard';

import * as UserActions from './../redux/actions/users';
import Profile from '../containers/private/Profile';
import PostGig from '../containers/private/PostGig';
import PostService from '../containers/private/PostService';
import EditService from '../containers/private/EditService';
import EditGig from '../containers/private/EditGig';
import Message from '../containers/private/Message';
import Notifications from '../containers/private/Notifications';
import Testimonials from '../containers/admin/Testimonials';
import Subscriptions from '../containers/admin/Subscriptions';
import Users from '../containers/admin/Users';
import Payments from '../containers/admin/Payments';
import Navbar from '../components/Navbar';


const VendorRoutes = () => (
    <>
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/message/:chatId?" exact component={Message} />
        <Route path="/notifications" exact component={Notifications} />
        <Route path="/service/post" exact component={PostService} />
        <Route path="/service/edit/:id" exact component={EditService} />
    </>
)

const CustomerRoutes = () => (
    <>
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/profile" exact component={Profile} />
        <Route path="/message/:chatId?" exact component={Message} />
        <Route path="/notifications" exact component={Notifications} />
        <Route path="/gig/post" exact component={PostGig} />
        <Route path="/gig/edit/:id" exact component={EditGig} />
    </>
)

const AdminRoutes = () => (
    <>
        <Route path="/dashboard" exact component={Subscriptions} />
        <Route path="/users/all" exact component={Users} />
        <Route path="/payments/all" exact component={Payments} />
        <Route path="/subscriptions/manage" exact component={Subscriptions} />
        <Route path="/testimonials/manage" exact component={Testimonials} />
    </>
)

class DashboardLayout extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.dispatch(UserActions.getProfile());
        this.props.dispatch(UserActions.getBusinessProfile());
    }

    // componentWillReceiveProps(nextProps) {
    //     if (nextProps.users.user) {
    //         return true;
    //     }
    // }

    logout = () => {
        this.props.dispatch(UserActions.logoutUser());
    }

    render() {
        const { users: { user } } = this.props;
        const profile = user || JSON.parse(window.localStorage.getItem('_profile'));
        const isAuth = window.localStorage.getItem("accessToken");
        const path = this.props.location.pathname;
        const isAdmin = profile && profile.admin_level === 1;
        const typeSpecificRoutes = profile 
            ? profile.admin_level === 1
                ? <AdminRoutes />
                : profile.user_type === "vendor" 
                    ? <VendorRoutes /> 
                    : profile.user_type === "customer" 
                        ? <CustomerRoutes />
                        : null
            : null
        return (
            <>
                <Switch>
                    {
                        profile && profile.admin_level !== 1
                        ? profile.user_type === "vendor" 
                            ? <VendorRoutes /> 
                            : profile.user_type === "customer" 
                                ? <CustomerRoutes />
                                : null
                        : <AdminRoutes />
                    }
                    {typeSpecificRoutes}
                </Switch>
            </>
        );
    }
}

export default withRouter(
    connect(state => ({
        users: state.users
    }))(DashboardLayout)
);
