import React from 'react';
import moment from 'moment';
import validator from 'validator';
import { formatter } from '../../utils/formatter';
import { confirmAlert } from 'react-confirm-alert';
import { MODE, PROD_BASE_PUBLIC_URL, DEV_BASE_PUBLIC_URL } from './../../constants';


const base_url = MODE === "PROD" ? PROD_BASE_PUBLIC_URL : DEV_BASE_PUBLIC_URL;

const ServiceItem = (props) => {
    const { service } = props;
    const media = `${base_url}/${service && service.media}`
    const defaultMedia = "https://www.google.com/url?sa=i&url=https%3A%2F%2Fpngimage.net%2Fdefault-image-png-8%2F&psig=AOvVaw1iiS7sSghqoo5I3vGZ6X8W&ust=1582063189476000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCOj9qvbK2ecCFQAAAAAdAAAAABAD"

    const _deleteService = (service, e) => {
        e.preventDefault();
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h1>Are you sure?</h1>
                    <p>You want to delete this file?</p>
                    <button className="btn-close" onClick={onClose}>No</button>
                    <button
                      onClick={() => {
                        // this.deleteSubscription(id);
                        // props.selectService.bind(this, service)
                        onClose();
                      }}
                    >
                      Yes, Delete it!
                    </button>
                  </div>
                );
            }
          });
    }

    return (
        <div className="col-12 grid-card p-0 mb-3">
            <div className="card cardresult shadow w-100">
                <div className="row h-100 no-gutters">
                    <div className="col-md-4 h-100">
                        <div className="card-imgs rounded-lefts h-100s">
                            <img src={media} className="img-fluid bg-side" />
                        </div>
                    </div>
                    <div className="col-md-8 p-0">
                        <div className="card-body justify-content-between py-2 px-3 p-md-3 h-100">
                            <div className="row m-0 mb-2 mb-md-4 justify-content-between ">
                                <div className="col-12 col-md-6 pl-0 float-none float-md-left">
                                    <p className="card-text">
                                        <span className="postime ml-2">
                                            <small className="text-muted"><small><i className="mdi mdi-clock"></i></small> <b>Posted {moment(service && service.created_at).fromNow()}</b></small>
                                        </span>
                                    </p>
                                </div>
                                <div className="col-12 col-md-6 p-0 float-none float-md-right text-left text-md-right">
                                    <p>
                                        {
                                            service && service.tags ? service.tags.split(',').map((tag, i) => {
                                                return <a className="badge sear badge-success">#{tag.trim()}</a>
                                            }) : null
                                        }
                                    </p>
                                </div>
                            </div>
                            <h5 className="card-title mb-2">{service && service.name}</h5>

                            <div className="row m-0 mb-2 mb-md-4 flex-column justify-content-between">
                                <p className="card-text">
                                    <small className="text-muted">Salary range:</small>
                                </p>
                                <h6 className="card-text">{service && formatter.format(service.budget_start)} - {service && formatter.format(service.budget_end)}</h6>
                            </div>
                            {
                                props.isOwner
                                    ? <div className="action-center">
                                        <a href={`/service/view/${service && service.id}`}><button className="action">View details</button></a>
                                        <div className="float-right">
                                            <a href={`/service/edit/${service && service.id}`}><button className="action">Edit</button></a>
                                            <button className="action action-delete" data-toggle="modal" data-target="#editServiceModals" onClick={_deleteService.bind(this, props.service)}>Delete</button>
                                        </div>
                                    </div>
                                    : <div className="action-center">
                                        <a href={`/service/view/${service && service.id}`}><button className="action">View details</button></a>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServiceItem;