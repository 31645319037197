import axios from 'axios';
import Swal from 'sweetalert2';
import { MODE, PROD_BASE_URL, DEV_BASE_URL } from './../../constants';


const base_url = MODE === "PROD" ? PROD_BASE_URL : DEV_BASE_URL;
const accessToken = window.localStorage.getItem('accessToken');
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};
const configAuth = {
    headers: {
        'Accept': 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
    }
};
const defaultAxios = axios.create({
    ...config,
    baseURL: base_url
});
const authAxios = axios.create({
    ...configAuth,
    baseURL: base_url
});

export const sendContactMessage = ({
    name,
    email,
    phoneNumber,
    subject,
    message,
}) => {
    return dispatch => {
        dispatch({
            type: 'SEND_CONTACT_MESSAGE_PENDING'
        })
        const fd = new FormData();
        fd.append("name", name);
        fd.append("email", email);
        fd.append("phone", phoneNumber);
        fd.append("subject", subject);
        fd.append("message", message);
        return defaultAxios.post('/create-contact', fd)
            .then(res => {
                const { status, error } = res.data;
                if (status === 1) {
                    dispatch({
                        type: 'SEND_CONTACT_MESSAGE_SUCCESS'
                    })
                } else {
                    dispatch({
                        type: 'SEND_CONTACT_MESSAGE_ERROR',
                        error
                    })
                    Swal.fire('An error occured', `Failed to send contact message: ${error}`, 'error')
                }
            })
            .catch(error => {
                if (process.env.NODE_ENV === "development")
                    console.warn(error)
                dispatch({
                    type: 'SEND_CONTACT_MESSAGE_ERROR',
                })
                Swal.fire('An error occured', `Failed to send contact message: ${error.message}`, 'error')
            })
    }
}