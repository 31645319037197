import axios from 'axios';
import Swal from 'sweetalert2'
import { MODE, PROD_BASE_URL, DEV_BASE_URL } from './../../constants';


const base_url = MODE === "PROD" ? PROD_BASE_URL : DEV_BASE_URL;
const accessToken = window.localStorage.getItem('accessToken');
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};
const configAuth = {
    headers: {
        'Accept': 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
    }
};


export const createTestimonials = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'CREATE_TESTIMONIAL_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/create-testimonial`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'CREATE_TESTIMONIAL_SUCCESS',
                    loading: false,
                    error: null
                })
                Swal.fire(
                    'Testimonial Creation Successful',
                    'You have successfully created a testimonial',
                    'success'
                ).then(() => {
                    window.location.reload();
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'CREATE_TESTIMONIAL_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Testimonial Creation Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};

export const updateTestimonial = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_TESTIMONIAL_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/update-testimonial`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'UPDATE_TESTIMONIAL_SUCCESS',
                    loading: false,
                    error: null
                })
                Swal.fire(
                    'Testimonial Update Successful',
                    'You have successfully updated a testimonial',
                    'success'
                ).then(() => {
                    window.location.reload();
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'UPDATE_TESTIMONIAL_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Testimonial Update Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};

export const deleteTestimonial = (id) => {
    return (dispatch) => {
        dispatch({
            type: 'DELETE_TESTIMONIAL_PENDING',
            loading: true,
            error: null
        })
        return axios.delete(`${base_url}/delete-testimonial/${id}`, configAuth)
            .then((response) => {
                dispatch({
                    type: 'DELETE_TESTIMONIAL_SUCCESS',
                    loading: false,
                    error: null
                })
                Swal.fire(
                    'Testimonial Deletion Successful',
                    'You have successfully created a testimonial',
                    'success'
                ).then(() => {
                    window.location.reload();
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'DELETE_TESTIMONIAL_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Testimonial Deletion Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};

export const approveTestimonial = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'APPROVE_TESTIMONIAL_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/approve-testimonial`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'APPROVE_TESTIMONIAL_SUCCESS',
                    loading: false,
                    error: null
                })
                Swal.fire(
                    'Testimonial Approval Successful',
                    'You have successfully approved this testimonial',
                    'success'
                ).then(() => {
                    window.location.reload();
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'APPROVE_TESTIMONIAL_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Testimonial Approval Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};

export const disapproveTestimonial = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'DISAPPROVE_TESTIMONIAL_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/disapprove-testimonial`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'DISAPPROVE_TESTIMONIAL_SUCCESS',
                    loading: false,
                    error: null
                })
                Swal.fire(
                    'Testimonial Disapproval Successful',
                    'You have successfully disapproved this testimonial',
                    'success'
                ).then(() => {
                    window.location.reload();
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'DISAPPROVE_TESTIMONIAL_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Testimonial Disapproval Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};

export const fetchAllTestimonials = () => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_ALL_TESTIMONIALS_PENDING',
            loading: true,
            testimonials: null,
            error: null
        })
        return axios.get(`${base_url}/view-testimonials`, config)
            .then((response) => {
                const testimonials = response.data.data;
                dispatch({
                    type: 'FETCH_ALL_TESTIMONIALS_SUCCESS',
                    loading: false,
                    testimonials,
                    error: null
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'FETCH_ALL_TESTIMONIALS_FAILED',
                    loading: false,
                    testimonials: null,
                    error: error.response
                })
            });
    };
};

export const fetchAllApprovedTestimonials = () => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_ALL_TESTIMONIALS_PENDING',
            loading: true,
            testimonials: null,
            error: null
        })
        return axios.get(`${base_url}/approved-testimonials`, config)
            .then((response) => {
                const testimonials = response.data.data;
                dispatch({
                    type: 'FETCH_ALL_TESTIMONIALS_SUCCESS',
                    loading: false,
                    testimonials,
                    error: null
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'FETCH_ALL_TESTIMONIALS_FAILED',
                    loading: false,
                    testimonials: null,
                    error: error.response
                })
            });
    };
};
