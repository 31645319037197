const initialState = {
    status: 'IDLE',
    error: null
}

export default function ContactReducer(state = initialState, action) {
    switch (action.type) {
        case 'SEND_CONTACT_MESSAGE_PENDING':
            return { ...state, status: 'PENDING', error: null }
        case 'SEND_CONTACT_MESSAGE_SUCCESS':
            return { ...state, status: 'IDLE' }
        case 'SEND_CONTACT_MESSAGE_ERROR':
            return { ...state, status: 'ERROR', error: action.error }
        default:
            return state
    }
}