import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as TestimonialActions from '../../redux/actions/testimonials';
import Empty from '../../components/Empty';
import Loading from '../../components/Loading';


class Testimonials extends Component {

	componentDidMount() {
        this.props.dispatch(TestimonialActions.fetchAllApprovedTestimonials());
	}

	render() {
		const { testimonials: { testimonials, testimonialsLoading } } = this.props;
		return (
			<article className="top-background result w-100 h-100">
				<div className="container py-3 p-md-3">
					<div className="row w-100 mx-0">
						<section className="main-body p-5 col-12">
							<div className="priceall">
								<h2 className="text-center px-md-5"><b>Testimonials</b></h2>
								{/* <p className="text-center graytxt px-md-5 mb-4">What are our customers and vendors saying?</p> */}
								<div className="row w-100 mx-0">
									{
										testimonialsLoading
										? <div className="col-md-12 text-center">
											<Loading />
										</div>
										: testimonials && testimonials.length > 0 ? testimonials.map((testimonial, i) => {
											return (
												<div className="pricesbx text-center col-12 col-lg-4 mb-4">
													<h5 className="w-100 p-3 rounded">{testimonial.name}</h5>
													<div className="py-4 px-3 rounded shadow">
														<p>{testimonial.message}</p>
													</div>
												</div>
											)
										})
										: <Empty
											title="Empty Testimonials!" 
											subText="Sorry, there are no testimonies from anyone yet" />
									}
								</div>
							</div>
						</section>
					</div>
				</div>
			</article>
		)
	}
}

export default withRouter(
    connect(state => ({
      testimonials: state.testimonials
    }))(Testimonials)
);