import React from 'react'
import { connect } from 'react-redux'
import * as NotificationsActions from '../../redux/actions/notifications'
import Loading from '../../components/Loading';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Empty from '../../components/Empty';

const _formatDate = date => moment(date).fromNow()

const NotificationItem = ({ title, message, link, date, read, markAsRead }) => {
    const handleClick = event => {
        event.preventDefault()
        event.stopPropagation()
        if (typeof markAsRead === "function")
            markAsRead()
    }
    return (
        <Link
            to={link}
            className="list-group-item list-group-item-action notification-item"
        >
            <div className="d-flex w-100 justify-content-between">
                <h5 className="mb-2">{title}</h5>
                <small className="notification-item-date">
                    {_formatDate(date)}
                </small>
            </div>
            <p className="mb-2">{message}</p>
            {read === 0 && (
                <button
                    type="button"
                    className="btn font-weight-bold mark-as-read-button d-flex align-items-center ml-auto"
                    onClick={handleClick}
                >
                    <i className="fas fa-check mr-2"></i>
                    Mark as read
            </button>
            )}
        </Link>
    )
}

class Notifications extends React.Component {

    state = {
        activeTab: 'unread'
    }

    async componentDidMount() {
        await this.props.dispatch(NotificationsActions.fetchNotifications())
    }

    _markAsRead = async notificationId => {
        await this.props.dispatch(NotificationsActions.markNotificationAsRead({
            notificationId
        }))
    }

    _setActiveTab = tabId => {
        this.setState({
            activeTab: tabId
        })
    }

    render() {
        const { activeTab } = this.state;
        const { notifications } = this.props;
        const { loadingState, notifications: noticationsStore, allNotifications, unreadNotifications } = notifications;
        const notificationsArray = activeTab === "unread" ? unreadNotifications : allNotifications
        return (
            <div className="container py-3 p-md-3">
                <div className="row w-100 my-3">
                    <div className="col">
                        <h4 className="font-weight-bold">Notifications</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="card w-100 shadow" style={{
                            maxHeight: "unset",
                            height: "unset",
                            transform: "none"
                        }}>
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <button
                                        type="button"
                                        onClick={() => this._setActiveTab('unread')}
                                        className={`nav-link ${activeTab === 'unread' ? 'active' : ''}`}
                                        style={{
                                            borderRadius: 0
                                        }}
                                    >
                                        Unread
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button
                                        type="button"
                                        onClick={() => this._setActiveTab('all')}
                                        className={`nav-link ${activeTab === 'all' ? 'active' : ''}`}
                                        style={{
                                            borderRadius: 0
                                        }}
                                    >
                                        All
                                    </button>
                                </li>
                            </ul>
                            <div className="card-body px-0 py-0">
                                {loadingState === "PENDING" && <Loading />}
                                {loadingState === "SUCCESS" && (
                                    notificationsArray.length > 0 
                                    ? notificationsArray.map(notificationId => {
                                        const notification = noticationsStore[notificationId]
                                        return (
                                            <NotificationItem
                                                key={notification.id}
                                                title={notification.title}
                                                message={notification.message}
                                                link={notification.link}
                                                read={notification.read}
                                                date={notification.upated_at}
                                                markAsRead={() => this._markAsRead(notification.id)}
                                            />
                                        )
                                    })
                                    : <Empty title="Empty Notifications" subText="No notifications found yet" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
    notifications: state.notifications
}))(Notifications);