import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as UserActions from '../../redux/actions/users';
import Empty from '../../components/Empty';
import Loading from '../../components/Loading';
import * as PaymentActions from '../../redux/actions/payments';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
const { SearchBar } = Search;


class Users extends Component {

    state = {
        data: [],
        originalData: [],
        loading: true,
        currentPage: 1
    }

    componentDidMount() {
        this.props.dispatch(UserActions.fetchAllUsers(1)).then(() => {
            const { users: { users, loading } } = this.props;
            this.setState({ data: users, originalData: users, loading });
        });
    }

    gotoPrevPage = () => {
        this.setState({ loading: true })
        const { users: { lastPage } } = this.props;
        const { currentPage } = this.state;
        if (currentPage > 1) {
            this.setState({ currentPage: this.state.currentPage - 1 });
            this.props.dispatch(UserActions.fetchAllUsers(this.state.currentPage - 1)).then(() => {
                const { users: { users, loading } } = this.props;
                this.setState({ data: users, originalData: users, loading });
            });
        }
        window.scrollTo(0, 0);
    }

    gotoNextPage = () => {
        this.setState({ loading: true })
        const { users: { lastPage } } = this.props;
        const { currentPage } = this.state;
        if (currentPage < lastPage) {
            this.setState({ currentPage: this.state.currentPage + 1 });
            this.props.dispatch(UserActions.fetchAllUsers(this.state.currentPage + 1)).then(() => {
                const { users: { users, loading } } = this.props;
                this.setState({ data: users, originalData: users, loading });
            });
        }
        window.scrollTo(0, 0);
    }

    gotoSpecificPage = (pageNo) => {
        const { users: { lastPage } } = this.props;
        if (pageNo <= lastPage && pageNo >= 1) {
            this.setState({ currentPage: pageNo });
            this.props.dispatch(UserActions.fetchAllUsers(pageNo));
        }
    }

    handleFilter = (e) => {
        e.preventDefault();
        const keyword = e.target.value;
        const { data, originalData } = this.state;

        if (keyword) {
            const result = data.filter((item) => {
                if (item.email.includes(keyword)) return item;
            })
            this.setState({ data: result })
        } else {
            this.setState({ data: originalData })
        }
    }

    reactivatePlan = () => {
        const { payments: { subscriptionData } } = this.props;
        const ravePlanId = subscriptionData && subscriptionData[0] && subscriptionData[0].id;
        this.props.dispatch(PaymentActions._reactivateSubscription(ravePlanId))
    }

    cancelPlan = () => {
        const { payments: { subscriptionData } } = this.props;
        const ravePlanId = subscriptionData && subscriptionData[0] && subscriptionData[0].id;
        this.props.dispatch(PaymentActions._cancelSubscription(ravePlanId))
    }

    getUserSubscriptions = (id) => {
        this.props.dispatch(PaymentActions.getAutoRenewedPlansUser(id));
    }

    render() {
        const { payments: { loading, subscriptionData }, users: { lastPage } } = this.props;
        const plan_id = subscriptionData && subscriptionData[0] && subscriptionData[0].id;
        const plan_status = subscriptionData && subscriptionData[0] && subscriptionData[0].status;
        const { data, currentPage } = this.state;
        const users = data;
        const usersLoading = this.state.loading;
        // name, firstname, lastname, user_type, email, plan_name, plan_id
        const columns = [{
            dataField: 'name',
            text: 'Business Name'
        }, {
            dataField: 'firstname',
            text: 'First Name'
        }, {
            dataField: 'lastname',
            text: 'Last Name'
        }, {
            dataField: 'user_type',
            text: 'User Type'
        }, {
            dataField: 'id',
            text: 'User ID'
        }, {
            dataField: 'email',
            text: 'Email Address'
        }, {
            dataField: 'plan_name',
            text: 'Plan',
            formatter: (cell, row, rowIndex, formatExtraData) => (
                row.plan_name || 'N/A'
            ),
        }, {
            dataField: 'email',
            formatExtraData: 'email',
            formatter: (cell, row, rowIndex, formatExtraData) => (
                //  {
                row.plan_id
                    ? <div className="action-buttons">
                        <button type="button" onClick={this.getUserSubscriptions.bind(this, row.id)} data-toggle="modal" data-target="#exampleModal">Manage Plans</button>
                    </div>
                    : 'N/A'
                // }
            ),
            text: 'Action'
        }];
        return (
            <article className="top-background result w-100 h-100">
                <div className="container py-3 p-md-3">
                    <div className="row w-100 mx-0">
                        <section className="main-body p-5s col-12" style={{ marginTop: '50px' }}>
                            <div className="priceall">
                                <div className="row w-100 mx-0 mt-4">
                                    <div className="col-md-12 table-responsive">
                                        {
                                            usersLoading
                                                ? <div className="col-md-12 text-center">
                                                    <Loading />
                                                </div>
                                                : data && data.length > 0
                                                    ? <ToolkitProvider
                                                        keyField="id"
                                                        data={data}
                                                        columns={columns}
                                                        search
                                                    >
                                                        {
                                                            props => (
                                                                <div>
                                                                    <div className="row no-margin">
                                                                        <div className="col-md-8 no-padding">
                                                                            <h3 className="text-centers px-md-5s mb-3"><b>All Users ({data && data.length || 0})</b></h3>
                                                                        </div>
                                                                        <div className="col-md-4 no-padding">
                                                                            <SearchBar {...props.searchProps} style={{ boxShadow: 'none', width: '100%' }} placeholder="Search the table" />
                                                                        </div>
                                                                    </div>
                                                                    <BootstrapTable
                                                                        {...props.baseProps}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </ToolkitProvider>
                                                    : <div className="col-md-12">
                                                        <Empty
                                                            title="Empty User List!"
                                                            subText="Sorry, there are no users registered yet." />
                                                    </div>
                                        }
                                    </div>
                                    <div className="col-12 my-3 text-center">
                                        {
                                            currentPage > 1 && <button className="btn button2 mr-3" onClick={this.gotoPrevPage}>Previous Page</button>
                                        }
                                        {
                                            currentPage < lastPage && <button className="btn button2" onClick={this.gotoNextPage}>Next Page</button>
                                        }
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        {currentPage === lastPage && <p className="text-center">You have reached the end of the list</p>}
                                    </div>
                                </div>
                            </div>
                        </section>
                        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div class="modal-dialog" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">Manage Plans</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <p>Status: {plan_status}</p>
                                        {
                                            !loading && subscriptionData
                                                ? <>
                                                    <button className="btn-action mr-2 mb-2" style={{ lineHeight: '15px' }} onClick={this.reactivatePlan.bind(this, plan_id)}>Reactivate Plan</button>
                                                    {
                                                        plan_status !== "cancelled"
                                                            ? <button className="btn-action cancel" style={{ lineHeight: '15px' }} onClick={this.cancelPlan.bind(this, plan_id)}>Cancel Plan</button>
                                                            : null
                                                    }
                                                </>
                                                : loading && !subscriptionData ? 'loading...' : 'loading..'
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </article >
        )
    }
}

export default withRouter(
    connect(state => ({
        users: state.users,
        payments: state.payments
    }))(Users)
);