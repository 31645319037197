import React, { Component } from 'react';
import FormValidator from "../../utils/FormValidator";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import * as UserActions from '../../redux/actions/users';


class Signup extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            { field: "email", method: "isEmail", validWhen: true, message: "This must be a valid email." },
            { field: "user_type", method: "isEmpty", validWhen: false, message: "User Type cannot be empty." },
            { field: "phone", method: "isEmpty", validWhen: false, message: "Phone Number cannot be empty." },
            { field: "firstname", method: "isEmpty", validWhen: false, message: "First Name cannot be empty." },
            { field: "lastname", method: "isEmpty", validWhen: false, message: "Last Name cannot be empty." },
            { field: "password", method: "isEmpty", validWhen: false, message: "Password cannot be empty." },
            { field: "c_password", method: "isEmpty", validWhen: false, message: "Password does not match." }
        ]);

        this.state = {
            user: {
                email: "",
                user_type: "",
                phone: "",
                firstname: "",
                lastname: "",
                password: "",
                c_password: ""
            },
            validation: this.validator.reset()
        }
    }

    handleChange = (propertyName, e) => {
        e.preventDefault();
        const user = this.state.user;
        user[propertyName] = e.target.value;
        this.setState({ user })
    }

    register = (e) => {
        e.preventDefault();
        const user = this.state.user;

        const payload = new FormData();
        Object.keys(user).forEach((key) => {
            payload.append(key, user[key])
        })

        const validation = this.validator.validate(user);

        if (validation.isValid) {
            this.props.dispatch(UserActions.register(payload));
            this.setState({ validation });
        } else {
            this.setState({ validation });
        }
    }

    render() {
        const { validation } = this.state;
        const { error, loading } = this.props.users;
        return (
            <article style={{ backgrounds: '#ffa010', backgroundImage: 'url(../img/loginbackground.png)', minHeight: '100vh' }} className="signup-login w-100 h-100">
                <div className="container py-3 p-md-3">
                    <div className="row w-100 mx-0 justify-content-center align-items-center">
                        <section className="main-body col-md-6">
                            <div className="m-5s">
                                <a href="/" className="navbar-brand pr-4 mb-5 d-block text-center" rel="home" itemprop="url">
                                    {/* <img width="98" height="42" src="../img/elfrique-logo-white.png" className="custom-logo" alt="Elfrique" itemprop="logo" /> */}
                                </a>
                                <div className="the-back p-5s pt-4">
                                    <div className="userall">
                                        <h4 className="graytxt border-bottom text-center pb-3 mb-3">Create an account</h4>
                                        <div className="container-fluid">
                                            <div className="row m-0 justify-content-center text-center">
                                                <div className="col-12 p-0 login-wrap">
                                                    <div className="login-form">
                                                        <form className="">
                                                            <div className="form-row">
                                                                <div className="form-group col-6 text-left">
                                                                    <input type="text" onChange={this.handleChange.bind(this, "firstname")} className="form-control" placeholder="First Name" />
                                                                    { validation.firstname.isValid ? '' : <span className="error-message text-left">{validation.firstname.message}</span> }
                                                                </div>
                                                                <div className="form-group col-6 text-left">
                                                                    <input type="text" onChange={this.handleChange.bind(this, "lastname")} className="form-control" placeholder="Last Name" />
                                                                    { validation.lastname.isValid ? '' : <span className="error-message text-left">{validation.lastname.message}</span> }
                                                                </div>
                                                                <div className="form-group col-12 text-left">
                                                                    <input type="email" onChange={this.handleChange.bind(this, "email")} className="form-control shadows" placeholder="Email Address" />
                                                                    { validation.email.isValid ? '' : <span className="error-message text-left">{validation.email.message}</span> }
                                                                </div>
                                                                <div className="form-group col-12 text-left">
                                                                    <input type="tel" onChange={this.handleChange.bind(this, "phone")} className="form-control" placeholder="Phone Number" />
                                                                    { validation.phone.isValid ? '' : <span className="error-message text-left">{validation.phone.message}</span> }
                                                                </div>
                                                                <div className="form-group col-12 text-left">
                                                                    <label>What do you want to do on the platform?</label>
                                                                    <select onChange={this.handleChange.bind(this, "user_type")} className="form-control">
                                                                        <option value="">Select user type</option>
                                                                        <option value="vendor">Event Vendor; I want to sell</option>
                                                                        <option value="customer">Customer; I want to find vendors</option>
                                                                    </select>
                                                                    { validation.user_type.isValid ? '' : <span className="error-message text-left">{validation.user_type.message}</span> }
                                                                </div>
                                                                <div className="form-group col-6 text-left">
                                                                    <input type="password" onChange={this.handleChange.bind(this, "password")} className="form-control shadows" placeholder="Password" />
                                                                    { validation.password.isValid ? '' : <span className="error-message text-left">{validation.password.message}</span> }
                                                                </div>
                                                                <div className="form-group col-6 text-left">
                                                                    <input type="password" onChange={this.handleChange.bind(this, "c_password")} className="form-control" placeholder="Confirm Password" />
                                                                    { validation.c_password.isValid ? '' : <span className="error-message text-left">{validation.c_password.message}</span> }
                                                                </div>
                                                            </div>
                                                            <button className="btn w-100 shadows" onClick={this.register}>{loading ? 'Loading...' : 'Create Account' }</button>
                                                        </form>

                                                        <p className="text-left mt-4">Have an account already?  <Link to="/login">Log in</Link></p>

                                                        <p className="text-left mt-4">Forgot password?  <Link to="/forgot/password">Click here</Link></p>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </article>
        )
    }
}

export default withRouter(
    connect(state => ({
      users: state.users
    }))(Signup)
);