import React from 'react';
import Loading from './../../components/Loading';

const PersonalProfileComponent = (props) => {
    const { userLoading, user,  validation, handleChange, updatePersonalProfile, updatePersonalProfileLoading} = props;
    const profile = user;
    return userLoading ? <Loading /> : user ? (
        <article className="signup-login listin w-100 h-100">
            <div className="container py-3 p-md-3">
                <div className="row w-100 mx-0">
                    <section className="main-body p-5s col-12">
                        <div className="">
                            <div className="the-back p-5s">
                                <div className="userall">
                                    <div className="border-bottoms">
                                        <h2 className="graytxt text-center pb-3 mb-5s">Edit Personal Profile</h2>
                                    </div>
                                    <div className="container-fluid">
                                        <div className="signup-form row m-0 justify-content-center">
                                            <div className="col-md-8 p-0 login-wrap">
                                                <div className="profile-form">
                                                    <form className="w-100 mx-md-3 px-md-2">
                                                        <div className="form-row py-3s border-bottoms">
                                                            <div className="form-group col-12 text-left"> 
                                                                <label for="ControlListingName">Firstname</label>
                                                                { validation.firstname.isValid ? '' : <span className="error-message text-left"> {validation.firstname.message}</span> }
                                                                <input type="text" value={profile && profile.firstname} className="form-control" onChange={handleChange.bind(this, "firstname")} placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div className="form-row py-3s border-bottoms">
                                                            <div className="form-group col-12 text-left"> 
                                                                <label for="ControlListingName">Lastname</label>
                                                                { validation.lastname.isValid ? '' : <span className="error-message text-left"> {validation.lastname.message}</span> }
                                                                <input type="text" value={profile && profile.lastname} className="form-control" onChange={handleChange.bind(this, "lastname")} placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div className="form-row py-3s border-bottoms">
                                                            <div className="form-group col-12 text-left"> 
                                                                <label for="ControlListingName">Phone</label>
                                                                { validation.phone.isValid ? '' : <span className="error-message text-left"> {validation.phone.message}</span> }
                                                                <input type="text" value={profile && profile.phone} className="form-control" onChange={handleChange.bind(this, "phone")} placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div className="form-row py-3s border-bottoms">
                                                            <div className="form-group col-12 text-left"> 
                                                                <label for="ControlListingName">State</label>
                                                                { validation.state.isValid ? '' : <span className="error-message text-left"> {validation.state.message}</span> }
                                                                <input type="text" value={profile && profile.state} className="form-control" onChange={handleChange.bind(this, "state")} placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div className="form-row py-3s border-bottoms">
                                                            <div className="form-group col-12 text-left"> 
                                                                <label for="ControlListingName">City</label>
                                                                { validation.city.isValid ? '' : <span className="error-message text-left"> {validation.city.message}</span> }
                                                                <input type="text" value={profile && profile.city} className="form-control" onChange={handleChange.bind(this, "city")} placeholder="" />
                                                            </div>
                                                        </div>
                                                        <div className="form-row">
                                                            <div className="form-group col-12 col-md-6 text-left"> 
                                                            </div>
                                                            <div className="form-group col-12 col-md-5 text-center text-md-right">
                                                                <button className="btn w-100 shadow" onClick={updatePersonalProfile}> { updatePersonalProfileLoading ? 'Loading...' : 'Update Personal Profile'}</button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </article>
    ) : 'Personal Profile details not loaded, please try again'
}

export default PersonalProfileComponent;