import axios from 'axios';
import Swal from 'sweetalert2'
import { MODE, PROD_BASE_URL, DEV_BASE_URL } from './../../constants';


const base_url = MODE === "PROD" ? PROD_BASE_URL : DEV_BASE_URL;
const accessToken = window.localStorage.getItem('accessToken');
const configAuth = {
    headers: {
        'Accept': 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
    }
};


export const createSubscription = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'CREATE_SUBSCRIPTION_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/create-plan`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'CREATE_SUBSCRIPTION_SUCCESS',
                    loading: false,
                    error: null
                })
                Swal.fire(
                    'Subscription Creation Successful',
                    'You have successfully created a subscription',
                    'success'
                ).then(() => {
                    window.location.reload();
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'CREATE_SUBSCRIPTION_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Subscription Creation Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};

export const updateSubscription = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_SUBSCRIPTION_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/update-plan`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'UPDATE_SUBSCRIPTION_SUCCESS',
                    loading: false,
                    error: null
                })
                Swal.fire(
                    'Subscription Update Successful',
                    'You have successfully updated a subscription',
                    'success'
                ).then(() => {
                    window.location.reload();
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'UPDATE_SUBSCRIPTION_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Subscription Update Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};

export const deleteSubscription = (id) => {
    return (dispatch) => {
        dispatch({
            type: 'DELETE_SUBSCRIPTION_PENDING',
            loading: true,
            error: null
        })
        return axios.delete(`${base_url}/delete-plan/${id}`, configAuth)
            .then((response) => {
                dispatch({
                    type: 'DELETE_SUBSCRIPTION_SUCCESS',
                    loading: false,
                    error: null
                })
                Swal.fire(
                    'Subscription Deleted Successful',
                    'You have successfully deleted a subscription',
                    'success'
                ).then(() => {
                    window.location.reload();
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'DELETE_SUBSCRIPTION_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Subscription Deletion Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};

export const fetchAllSubscriptions = () => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_ALL_SUBSCRIPTIONS_PENDING',
            loading: true,
            subscriptions: null,
            error: null
        })
        return axios.get(`${base_url}/view-plans`, configAuth)
            .then((response) => {
                const subscriptions = response.data.data;
                dispatch({
                    type: 'FETCH_ALL_SUBSCRIPTIONS_SUCCESS',
                    loading: false,
                    subscriptions,
                    error: null
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'FETCH_ALL_SUBSCRIPTIONS_FAILED',
                    loading: false,
                    subscriptions: null,
                    error: error.response
                })
            });
    };
};
