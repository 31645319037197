import React from 'react';

const Empty = ({title, subText}) => {
    return (
        <div className="row justify-content-center align-items-center" style={{height: "400px", width: "100%"}}>
            <div className="col-md-12 text-center empty-pane">
                <img src="./../../img/sad.svg" width="150px" className="img-fluid mb-4" />
                <h2>{title}</h2>
                <p>{subText}</p>
            </div>
        </div>
    )
} 

export default Empty;