import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormValidator from '../../utils/FormValidator';
import * as UsersActions from '../../redux/actions/users';
import BusinessProfileComponent from '../../components/BusinessProfile';
import PersonalProfileComponent from '../../components/PersonalProfile';


class Profile extends Component {

    constructor(props) {
        super(props);

        this.personalValidator = new FormValidator([
            { field: "firstname", method: "isEmpty", validWhen: false, message: "firstname cannot be empty." },
            { field: "lastname", method: "isEmpty", validWhen: false, message: "lastname cannot be empty." },
            { field: "phone", method: "isEmpty", validWhen: false, message: "phone cannot be empty." },
            { field: "state", method: "isEmpty", validWhen: false, message: "state cannot be empty." },
            { field: "city", method: "isEmpty", validWhen: false, message: "city cannot be empty." }
        ]);

        this.businessValidator = new FormValidator([
            { field: "address", method: "isEmpty", validWhen: false, message: "address cannot be empty." },
            { field: "state", method: "isEmpty", validWhen: false, message: "state cannot be empty." },
            { field: "city", method: "isEmpty", validWhen: false, message: "city cannot be empty." }
        ]);

        this.state = {
            view: "personal",
            profile: {
                firstname: "",
                lastname: "",
                phone: "",
                state: "",
                city: ""
            },
            business: {
                address: "",
                state: "",
                logo: "",
                city: "",
                category1: "",
                category2: "",
                business_type: "",
                listing_type: ""
            },
            personalValidation: this.personalValidator.reset(),
            businessValidation: this.businessValidator.reset()
        }
    }

    componentDidMount() {
        this.props.dispatch(UsersActions.getBusinessProfile());
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.users.user) {
            const { firstname, lastname, phone, state, city } = nextProps.users.user;
            const profile = { firstname, lastname, phone, state, city };
            Object.keys(profile).forEach((key) => {
                if (!profile[key]) {
                    profile[key] = "";
                }
            })
            this.setState({ profile })
        }
        if (nextProps.users.business) {
            const { address, state, city, logo, category1, category2, business_type, listing_type } = nextProps.users.business;
            const business = { address, state, city, logo, category1, category2, business_type, listing_type };
            Object.keys(business).forEach((key) => {
                if (!business[key]) {
                    business[key] = "";
                }
            })
            this.setState({ business })
        }
    }

    handleChangeView = (view) => {
        this.setState({ view })
    }

    handlePersonalChange = (propertyName, e) => {
        e.preventDefault();
        const profile = this.state.profile;
        profile[propertyName] = e.target.value;
        this.setState({ profile })
    }

    handleBusinessChange = (propertyName, e) => {
        e.preventDefault();
        const business = this.state.business;
        business[propertyName] = e.target.value;
        this.setState({ business })
    }

    updatePersonalProfile = (e) => {
        e.preventDefault();
        const profile = this.state.profile;

        const payload = new FormData();
        Object.keys(profile).forEach((key) => {
            payload.append(key, profile[key])
        })

        console.log(profile)

        const personalValidation = this.personalValidator.validate(profile);

        if (personalValidation.isValid) {
            this.props.dispatch(UsersActions.updatePersonalProfile(payload));
            this.setState({ personalValidation });
        } else {
            this.setState({ personalValidation });
        }
    }

    updateBusinessProfile = (e) => {
        e.preventDefault();
        const business = this.state.business;

        const payload = new FormData();
        Object.keys(business).forEach((key) => {
            payload.append(key, business[key])
        })

        const businessValidation = this.businessValidator.validate(business);

        if (businessValidation.isValid) {
            this.props.dispatch(UsersActions.updateBusinessProfile(payload));
            this.setState({ businessValidation });
        } else {
            this.setState({ businessValidation });
        }
    }

    render() {
        const { personalValidation, businessValidation, view, profile, business } = this.state;
        const { userLoading, updatePersonalProfileLoading } = this.props.users;
        const { businessLoading, updateBusinessProfileLoading } = this.props.users;


        return (
            <div>
                <div className="text-center" style={{marginTop: '40px'}}>
                    <button className={ view === "personal" ? "mr-3 active-profile-btn" : "mr-3 profile-btn" } onClick={() => this.handleChangeView("personal")}>Personal Profile</button>
                    <button className={ view === "business" ? "active-profile-btn" : "profile-btn" } onClick={() => this.handleChangeView("business")}>Business Profile</button>
                </div>
                {
                    (view === "personal") ?
                     <PersonalProfileComponent
                        user={profile}
                        userLoading={userLoading}
                        validation={personalValidation}
                        handleChange={this.handlePersonalChange}
                        updatePersonalProfile={this.updatePersonalProfile}
                        updatePersonalProfileLoading={updatePersonalProfileLoading} />
                    : <BusinessProfileComponent
                        business={business}
                        businessLoading={businessLoading}
                        validation={businessValidation}
                        handleChange={this.handleBusinessChange}
                        updateBusinessProfile={this.updateBusinessProfile}
                        updateBusinessProfileLoading={updateBusinessProfileLoading} />
                }
            </div>
        )
    }
}

export default withRouter(
    connect(state => ({
        users: state.users
    }))(Profile)
);