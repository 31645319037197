import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import FormValidator from '../../utils/FormValidator';
import * as MessagesActions from '../../redux/actions/messages';
import Loading from '../../components/Loading';
import moment from 'moment'

const ChatListItem = ({ recepientId, recepientName, recepientAvatar, lastMessage: { date, summary } = {}, active, className = '', ...props }) => {
    let classes = 'list-group-item list-group-item-action rounded-0';
    if (active) classes += ' active text-white';
    if (!!className) classes += ` ${className}`;
    const formatDate = date => moment(date).format("DD MMM");
    return (
        <Link to={`/message/${recepientId}`} className={classes}>
            <div className="media">
                <img src={recepientAvatar} alt={recepientName} width="50" className="rounded-circle" />
                <div className="media-body ml-4">
                    <div className="d-flex align-items-center justify-content-between mb-1">
                        <h6 className="mb-0">{recepientName}</h6>
                        <small className="small font-weight-bold">{date ? formatDate(date) : ''}</small>
                    </div>
                    <p className="font-italic mb-0 text-small">{summary || ''}</p>
                </div>
            </div>
        </Link>
    )
}

const ChatMessage = ({ sent = false, content, date, senderAvatar = null, senderName = null }) => {
    const formatDate = date => moment(date).format("HH:MM A | MMM DD");
    return (
        <div className={`media w-50 mb-3${sent ? ' ml-auto' : ''}`}>
            {!sent && (
                <img src={senderAvatar} alt={senderName} width="50" className="rounded-circle" />
            )}
            <div className="media-body ml-3">
                <div className={`${sent ? 'sent-message' : 'bg-light'} rounded py-2 px-3 mb-2`}>
                    <p className={`text-small mb-0 ${sent ? 'text-white' : 'text-muted'}`}>{content}</p>
                </div>
                <p className="small text-muted">{formatDate(date)}</p>
            </div>
        </div>
    )
}


class Message extends Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            { field: "message", method: "isEmpty", validWhen: false, message: "Message cannot be empty" },
        ]);

        this.state = {
            composedMessage: "",
            validation: this.validator.reset()
        }
    }

    async componentDidMount() {
        const {
            location: { search },
            match: { params },
            gigs: { gig },
            services: { service },
            history
        } = this.props;
        const urlParams = new URLSearchParams(search);
        this.props.dispatch(MessagesActions.fetchChats())
            .then(() => {
                const recepientId = parseInt(params.chatId)
                if (!isNaN(recepientId)) {
                    if (urlParams.has("new"))
                        return this.props.dispatch(MessagesActions.startNewChat({ recepientId }))
                    else
                        return this.props.dispatch(MessagesActions.fetchConversation({ contactID: recepientId }))
                }
            })
            .then(() => {
                const gigId = parseInt(urlParams.get("gig"))
                const serviceId = parseInt(urlParams.get("service"))
                if (!isNaN(gigId)) {
                    if (gig) {
                        this.setState({
                            composedMessage: `Hello. I am interested in your [${gig.name} - ${gig.description}] gig. Can we talk?`
                        }, this._sendMessge)
                    } else {
                        history.push(`/gig/view/${gigId}`)
                    }
                } else if (!isNaN(serviceId)) {
                    if (service) {
                        this.setState({
                            composedMessage: `Hello. I am interested in your [${service.name} - ${service.description}] service. Can we talk?`
                        }, this._sendMessge)
                    } else {
                        history.push(`/service/view/${serviceId}`)
                    }
                }
            })
    }

    _getMostRecentMessageExcerpt = contactID => {
        const { chats } = this.props.messages;
        const chat = chats.find(chat => chat.contactID === contactID)
        if (chat) {
            if (chat.messages.length > 0) {
                const lastIndex = chat.messages.length - 1
                const lastMessage = chat.messages[lastIndex]
                const summary = lastMessage.message.length > 15 ?
                    `${lastMessage.message.substr(0, 15)}...` : lastMessage.message
                return {
                    date: lastMessage.created_at,
                    summary
                }
            }
        }
        return {}
    }

    _sendMessge = (event) => {
        if (event) event.preventDefault()
        const { messages: { sendingMessage }, match } = this.props
        if (sendingMessage) return
        const { composedMessage: message } = this.state
        const validation = this.validator.validate({
            message
        })
        if (validation.isValid) {
            const recepientId = parseInt(match.params.chatId)
            this.props.dispatch(MessagesActions.sendMessage({
                recepientId,
                message
            })).then(() => {
                this.setState({
                    composedMessage: ''
                })
            })
        }
        this.setState({
            validation
        })
    }

    _handleChange = (event) => {
        const { value } = event.target
        this.setState({
            composedMessage: value
        })
    }

    _handleCloseChat = () => {
        const { history } = this.props;
        history.goBack();
    }

    render() {
        const { match, messages } = this.props;
        const { loadingState, contacts, chats, startingChat, sendingMessage } = messages;
        const activeChatId = parseInt(match.params.chatId)
        const activeChat = chats.find(chat => chat.contactID === activeChatId)
        const activeContact = contacts.find(contact => contact.id === activeChatId)
        return (
            <div className="container py-3 p-md-3">
                <div className="row chat-pane rounded-lg overflow-hidden shadow">
                    <div className="col-12 col-md-4 px-0 border-right" style={{ height: "100%" }}>
                        <div className="bg-white" style={{ height: "100%" }}>
                            <div className="bg-gray px-4 py-2 bg-light">
                                <p className="h5 mb-0 py-1">Recent Messages</p>
                            </div>
                            <div className="messages-box">
                                <div className="list-group rounded-0">
                                    {loadingState === "PENDING" && (
                                        <Loading />
                                    )}
                                    {
                                        contacts.map(contact => {
                                            return (
                                                <ChatListItem key={contact.id}
                                                    active={activeChatId === contact.id}
                                                    recepientAvatar="https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg"
                                                    recepientName={contact.name}
                                                    recepientId={contact.id}
                                                    lastMessage={this._getMostRecentMessageExcerpt(contact.id)}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class={`col-12 col-md-8 px-0 bg-white chat-box-container${activeChat ? ' chat-box-container-active' : ''}`}>
                        <button className="btn btn-block d-md-none close-chat-button" onClick={this._handleCloseChat}>
                            <i className="fas fa-arrow-left mr-3"></i>
                            Back
                        </button>
                        <div class={`px-4 py-5 chat-box bg-white`}>
                            {(startingChat || (activeChat && activeChat.loadingState === "PENDING")) && <Loading />}
                            {activeChat && activeChat.messages.map(message => {
                                return (
                                    <ChatMessage
                                        key={message.id}
                                        sent={message.sent}
                                        senderAvatar="https://res.cloudinary.com/mhmd/image/upload/v1564960395/avatar_usae7z.svg"
                                        content={message.message}
                                        date={message.created_at}
                                        senderName={activeContact.name}
                                    />
                                )
                            })}
                        </div>
                        <form action="#" className="bg-light border-top" onSubmit={this._sendMessge}>
                            <div className="input-group">
                                <input
                                    onChange={this._handleChange}
                                    type="text"
                                    placeholder="Type a message"
                                    aria-describedby="button-addon2"
                                    className="form-control rounded-0 border-0 py-4 bg-light"
                                    value={this.state.composedMessage}
                                />
                                <div className="input-group-append">
                                    <button id="button-addon2" type="submit" className="btn btn-link rounded-0" disabled={sendingMessage}> <i className="fa fa-paper-plane"></i></button>
                                </div>
                            </div>
                        </form>

                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(
    connect(state => ({
        messages: state.messages,
        gigs: state.gigs,
        services: state.services
    }))(Message)
);