import React from 'react';
import { Link } from 'react-router-dom';

const Footer = ({ handleAddSubscriber, handleChange, loading, validation }) => {
    console.log(validation, loading)
    return (
        <footer id="colophon" className="site-footer">
            <div className="footer-widget">
                <div className="container p-md-5s px-md-5s">
                    <div className="">
                        <div className="newletter row m-0s py-5s">
                            <div className="row w-100s m-0s align-items-center justify-content-start no-margin">
                                <div className="col-xs-12 col-sm-5">
                                    <h3>Newsletter</h3>
                                    <p className="">Subscribe to stay up to date on latest listings and jobs</p>
                                </div>
                                <form className="form-inline px-3 col-xs-12 col-sm-7 rounded">
                                    <div className="form-group form-row w-100 text-xs-center bg-light justify-content-center rounded">
                                        <div className="col-xs-8 col-md-5">
                                            { 
                                                !validation.name.isInvalid 
                                                ? <span className="error-message text-left">&nbsp;</span> : <span className="error-message text-left">{validation.name.message}</span> 
                                            }
                                            <input type="text" name="name" placeholder="Enter full name" className="form-control" onChange={handleChange.bind(this, "name")} />
                                        </div>
                                        <div className="col-xs-8 col-md-4">
                                            { 
                                                !validation.email.isInvalid 
                                                ? <span className="error-message text-left">&nbsp;</span> : <span className="error-message text-left">{validation.email.message}</span> 
                                            }
                                            <input type="email" name="mail" placeholder="Enter email a ddress" className="form-control" onChange={handleChange.bind(this, "email")} />
                                        </div>
                                        <div className="col-xs-6 col-md-3">
                                            {!validation.isValid ? <p className="mt-4"> </p> : <p className="mt-4"> </p> }
                                            {
                                                loading
                                                    ? <button name="submit" className="btn button">
                                                        Adding...
                                                    </button>
                                                    : <button name="submit" onClick={handleAddSubscriber} className="btn button">
                                                        Subscribe
                                                    </button>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="row m-0s pt-lg-5 widget-in border-top">
                            <div className="col-12 col-lg-4 my-4 my-lg-0 text-center text-lg-left pr-lg-5 flex-column" style={{padding: '0px'}}>
                                <h6>Contact us</h6>
                                <ul className="navbar-nav flex-column justify-content-start secondary-menu">
                                    <li className="nav-item"><a href="tel:+2349071130455" className="nav-link">Voice Calls Only: +234-907-113-0455</a></li>
                                    <li className="nav-item"><a href="tel:+2349013209138" className="nav-link">WhatsApp Only: +234-901-320-9138</a></li>
                                    <li className="nav-item"><a href="tel:+447452251356" className="nav-link">SMS Only: +44-745-225-1356</a></li>
                                    <li className="nav-item"><Link to="/contact" className="nav-link">Contact us</Link></li>
                                    <li className="nav-item">Email us: <a href="mail:info@elfrique.com" className="orangetxt nav-link">info@elfrique.com</a></li>
                                </ul>
                                <div className="row flex-column flex-md-row mt-3 my-md-4 px-lg-3 justify-content-between text-center text-lg-left">
                                    {/* <p>Email Us:<br /></p> */}
                                </div>
                            </div>
                            <div className="col-12 col-lg-4 my-4 my-md-0 text-center text-lg-left">
                                <h6>Company</h6>
                                <ul className="navbar-nav flex-column justify-content-start secondary-menu">
                                    <li className="nav-item"><a href="/testimonials" className="nav-link">Testimonials</a></li>
                                    <li className="nav-item"><a href="/pricing" className="nav-link">Pricing & Fees</a></li>
                                </ul>
                            </div>
                            <div className="col-12 col-lg-4 my-4 my-md-0 text-center text-lg-left">
                                <h6>Legal</h6>
                                <ul className="navbar-nav flex-column justify-content-start secondary-menu">
                                <li className="nav-item"><a href="https://elfrique.com/terms" target="_blank" className="nav-link">Terms of Service</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4 px-0">
                            <div className="social my-3">
                                <a href="https://www.facebook.com/elfrique/" className="orangetxt ml-0 d-inline-block"><i className="fab fa-facebook-f"></i></a>
                                <a href="https://twitter.com/elfrique" className="orangetxt ml-3 d-inline-block"><i className="fab fa-twitter"></i></a>
                                <a href="https://www.instagram.com/elfrique/" className="orangetxt ml-3 d-inline-block"><i className="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="site-copywrite">
                <div className="container py-3 border-top">
                    <div className="row justify-content-center">
                        <div className="row text-center flex-column flex-md-row text-md-left align-items-center justify-content-center col-12 col-lg-8">
                            <p>Copyright © 2015-2020 Elfrique Solutions Limited | Technology by <a href="https://www.chibuokemibezim.dev/" className="orangetxt">Chibuokem</a></p>
                        </div>
                        {/* <div className="col-12 col-lg-4 px-0">
                            <div className="social my-3 text-center text-md-right">
                                <a href="#" className="orangetxt ml-0 d-inline-block"><i className="fab fa-facebook-f"></i></a>
                                <a href="#" className="orangetxt ml-3 d-inline-block"><i className="fab fa-twitter"></i></a>
                                <a href="#" className="orangetxt ml-3 d-inline-block"><i className="fab fa-instagram"></i></a>
                                <a href="#" className="orangetxt ml-3 d-inline-block"><i className="fab fa-linkedin"></i></a>
                                <a href="#" className="orangetxt ml-3 d-inline-block"><i className="fab fa-youtube"></i></a>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;