import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import * as ServiceActions from '../../redux/actions/services';
import moment from 'moment';
import validator from 'validator';
import Loading from '../../components/Loading';
import ServiceItem from '../../components/ServiceItem';
import { formatter } from '../../utils/formatter';
import { MODE, PROD_BASE_PUBLIC_URL, DEV_BASE_PUBLIC_URL } from './../../constants';


const base_url = MODE === "PROD" ? PROD_BASE_PUBLIC_URL : DEV_BASE_PUBLIC_URL;

class InnerService extends Component {

	componentDidMount() {
		console.log(this.props)
		const { id } = this.props.match.params;
		this.props.dispatch(ServiceActions.fetchSingleService(id))
	}

	render() {
		const { services: { service, serviceLoading } } = this.props;
		const user = service && service.user;
		const userId = user && user.id;
		const serviceId = service && service.id;
		const media = `${base_url}/${service && service.media}`
		const defaultMedia = "https://www.google.com/url?sa=i&url=https%3A%2F%2Fpngimage.net%2Fdefault-image-png-8%2F&psig=AOvVaw1iiS7sSghqoo5I3vGZ6X8W&ust=1582063189476000&source=images&cd=vfe&ved=0CAIQjRxqFwoTCOj9qvbK2ecCFQAAAAAdAAAAABAD"
        const profile = JSON.parse(window.localStorage.getItem('_profile'));
		const subscriptionId = profile && profile.plan_id;
        const isLoggedIn = profile ? true : false;
		return serviceLoading ? <Loading /> : (
			<React.Fragment>
				<section className="banner-dashtop position-relative align-middle">
					<div className="position-absolute w-100 h-100">
						<div className="container p-md-1">
							<div className="row col-sm-12 h-100 mx-auto flex-column justify-content-center align-items-center text-white text-center">
								<div className="banner-top results row m-0  h-100 w-100 align-items-center justify-content-betweens">
									<div className="authorinfo row h-100 align-item-center col-12 col-md-7">
										<div className="row col-12 px-0 py-3 mx-0 flex-column justify-content-betweens text-left">
											<h2 className="black-text my-2 mx-0">{service && service.name}</h2>
											<p className="graytxt h5 font-weight-normal my-2 mx-0">{service && formatter.format(service.budget_start)} - {service && formatter.format(service.budget_end)}</p>
										</div>
									</div>
									<div className="h-100 col-12 col-md-5 text-center">
										<div className="row flex-column justify-content-betweens align-items-end w-100 h-100 mb-0 px-0 py-3 mx-0">
											<div className="row flex-column px-0 mx-0">
												{
													service && service.tags
														? <div className="pr-0 mt-2 text-right">
															<p>
																{
																	service.tags.split(',').map((tag, i) => {
																		return <a className="badge sear badge-success">{tag}</a>
																	})
																}
															</p>
														</div> : null
												}
												<p className="card-text mt-3 text-right">
													<span className="postime mr-4">
														<small className="text-muteds black-text"><small><b className="far fa-clock"></b></small> <b>Posted {moment(service && service.created_at).fromNow()}</b> </small>
													</span>
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<article className="top-background result w-100 h-100">
					<div className="container py-3 p-md-3">
						<div className="row">
							<section className="main-body p-0 col-12">
								<div className="banner-after pt-4 col-12 col-lg-9 float-none float-lg-left">
									<nav aria-label="breadcrumb">
										<ol className="breadcrumb">
											<li className="breadcrumb-item"><a href="/services">Services</a></li>
											<li className="breadcrumb-item actives"><a href="#">{service && service.name}</a></li>
										</ol>
									</nav>
									<div className="row m-0 banner-txt justify-content-between h-100 col-12">
										<div className="">
											<div style={{ backgroundImage: `url(${validator.isURL(media) ? media : defaultMedia})`, minHeight: '450px' }} className="img-fluid img rounded"></div>
											<div className="bg-white rounded shadow text-left my-4" style={{ padding: "20px 30px 40px" }}>
												<h3 className="w-100 graytxt border-bottom pb-2 mb-4 mt-2">Service Description</h3>
												<p className="graytxt">{service && service.description}</p>
											</div>
										</div>
										<div className="row w-100 justify-content-between py-3 my-3 border-bottom">
											<div className="grid-title">
												<h5>Similar Ads</h5>
											</div>
										</div>
										<div id="cardgrid" className="grid slide">
											<div className="">
												<div className="grid-item">
													<div className="row flex-column align-items-start justify-content-center justify-center-md-start m-0 pb-4">
														<ServiceItem service={service} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="banner-form col-12 col-lg-3 float-none float-lg-right pt-4">
									<div className="">
										<div className="top-author py-4 px-3 text-center mb-3 rounded shadow">
											<p className="row mb-2 px-0 mx-0 h5 font-weight-normal align-items-center justify-content-center py-1 graytxt border-bottom">{user && user.name} <span className="verif ml-1"></span></p>
											<div className="col-12 rounded-circle">
												<div style={{ backgroundImage: 'url(https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png)' }} className="authorinfo-img img-fluid border border-white rounded-circle m-auto shadow"></div>
											</div>
											{
												subscriptionId && isLoggedIn
												? <Link disabled={serviceLoading} to={`/message/${userId}?new=true&service=${serviceId}`} className="button messbut3 my-2">
													Contact Vendor
												</Link>
												: <button onClick={() => alert("Sorry, only logged in paid users can apply.")} className="button messbut3 my-2">
													Contact Vendor
												</button>
											}
											<div className="py-2 border-top">
												<p><span className="orangetxt"><i className="fas fa-star"></i></span><span className="orangetxt"><i className="fas fa-star"></i></span><span className="orangetxt"><i className="fas fa-star"></i></span></p>
												<p className="small">Registered {moment(user && user.created_at).format('LL')}</p>
											</div>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</article>
			</React.Fragment>
		)
	}
}

export default withRouter(
	connect(state => ({
		services: state.services
	}))(InnerService)
);