import axios from 'axios';
import Swal from 'sweetalert2'
import { MODE, PROD_BASE_URL, DEV_BASE_URL } from './../../constants';


const base_url = MODE === "PROD" ? PROD_BASE_URL : DEV_BASE_URL;
const accessToken = window.localStorage.getItem('accessToken');
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};
const configAuth = {
    headers: {
        'Accept': 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
    }
};


export const createGig = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'CREATE_GIG_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/create-gig`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'CREATE_GIG_SUCCESS',
                    loading: false,
                    error: null
                })
                Swal.fire(
                    'Gig Creation Successful',
                    'You have successfully created a gig',
                    'success'
                ).then(() => {
                    window.location.replace('/dashboard');
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'CREATE_GIG_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Gig Creation Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};

export const updateGig = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_GIG_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/update-gig`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'UPDATE_GIG_SUCCESS',
                    loading: false,
                    error: null
                })
                Swal.fire(
                    'Gig Creation Successful',
                    'You have successfully updated a gig',
                    'success'
                ).then(() => {
                    window.location.replace('/dashboard');
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'UPDATE_GIG_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Gig Creation Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};

export const fetchMyGigs = () => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_MY_GIGS_PENDING',
            loading: true,
            gigs: null,
            error: null
        })
        return axios.get(`${base_url}/get-authenticated-users-gig`, configAuth)
            .then((response) => {
                const gigs = response.data.data;
                dispatch({
                    type: 'FETCH_MY_GIGS_SUCCESS',
                    loading: false,
                    gigs,
                    error: null
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'FETCH_MY_GIGS_FAILED',
                    loading: false,
                    gigs: null,
                    error: error.response
                })
            });
    };
};

export const fetchAllGigs = (pageNo) => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_ALL_GIGS_PENDING',
            loading: true,
            gigs: null,
            error: null
        })
        return axios.get(`${base_url}/get-all-gigs?page=${pageNo}`, config)
            .then((response) => {
                const gigs = response.data.data.data;
                const lastPage = response.data.data.last_page;
                dispatch({
                    type: 'FETCH_ALL_GIGS_SUCCESS',
                    loading: false,
                    gigs,
                    lastPage,
                    error: null
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'FETCH_ALL_GIGS_FAILED',
                    loading: false,
                    gigs: null,
                    error: error.response
                })
            });
    };
};

export const fetchSingleGig = (gig_id) => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_SINGLE_GIG_PENDING',
            loading: true,
            gig: null,
            error: null
        })
        return axios.get(`${base_url}/get-gig/${gig_id}`, configAuth)
            .then((response) => {
                const gig = response.data.data;
                const status = response.data.status;
                if (status === 0) {
                    return Swal.fire(
                        'Gig no longer exist',
                        'This gig has most likely been deleted',
                        'error'
                    ).then(() => {
                        window.open('/jobs', '_self');
                    })
                }
                dispatch({
                    type: 'FETCH_SINGLE_GIG_SUCCESS',
                    loading: false,
                    gig,
                    error: null
                })
            })
            .catch((error) => {
                Swal.fire(
                    'Gig could not be displayed',
                    'An error occured, please refresh the page',
                    'error'
                )
                dispatch({
                    type: 'FETCH_SINGLE_GIG_FAILED',
                    loading: false,
                    gig: null,
                    error: error.response
                })
            });
    };
};

export const deleteGig = (id) => {
    return (dispatch) => {
        dispatch({
            type: 'DELETE_GIG_PENDING',
            loading: true,
            error: null
        })
        return axios.delete(`${base_url}/delete-gig/${id}`, configAuth)
            .then((response) => {
                dispatch({
                    type: 'DELETE_GIG_SUCCESS',
                    loading: false,
                    error: null
                })
                Swal.fire(
                    'Gig Deletion Successful',
                    'You have successfully deleted this gig',
                    'success'
                ).then(() => {
                    window.location.replace('/dashboard');
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'DELETE_GIG_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Gig Deletion Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};