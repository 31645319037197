import axios from 'axios';
import Swal from 'sweetalert2'
import { MODE, PROD_BASE_URL, DEV_BASE_URL } from './../../constants';


const base_url = MODE === "PROD" ? PROD_BASE_URL : DEV_BASE_URL;
const config = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
};


export const fetchAllVendors = () => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_ALL_VENDORS_PENDING',
            loading: true,
            error: null
        })
        return axios.get(`${base_url}/get-vendors`, config)
            .then((response) => {
                const vendors = response.data.data;
                dispatch({
                    type: 'FETCH_ALL_VENDORS_SUCCESS',
                    loading: false,
                    vendors,
                    error: null
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'FETCH_ALL_VENDORS_FAILED',
                    loading: false,
                    error: error.response
                })
            });
    };
}; 

export const fetchSingleVendor = (id) => {
    return (dispatch) => {
        dispatch({
            type: 'FETCH_VENDOR_PROFILE_PENDING',
            loading: true,
            vendor: null, 
            error: null
        })
        return axios.get(`${base_url}/get-user-by-id/${id}`, config)
            .then((response) => {
                const vendor = response.data.data;
                dispatch({
                    type: 'FETCH_VENDOR_PROFILE_SUCCESS',
                    loading: false,
                    vendor,
                    error: null
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'FETCH_VENDOR_PROFILE_FAILED',
                    loading: false,
                    vendor: null,
                    error: error.response
                })
            });
    };
};

export const updateProfile = (uid, payload) => {
    return (dispatch) => {
        dispatch({
            type: 'UPDATE_PROFILE_PENDING',
            loading: true,
            user: null,
            error: null
        })
        return axios.put(`${base_url}/users/${uid}`, payload, config)
            .then((response) => {
                const user = response.data.data;
                window.localStorage.setItem("_profile", JSON.stringify(user));
                dispatch({
                    type: 'UPDATE_PROFILE_SUCCESS',
                    loading: false,
                    user,
                    error: null
                })
            })
            .then(async() => {
                await Swal.fire(
                    'Update Profile Successful',
                    'You have successfully updated your account\'s profile',
                    'success'
                )
                window.location.reload();
            })
            .catch((error) => {
                Swal.fire(
                    'Update Profile Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
                dispatch({
                    type: 'UPDATE_PROFILE_FAILED',
                    loading: false,
                    user: null,
                    error: error.response
                })
            });
    };
};

export const logoutUser = () => {
    return (dispatch) => {
        window.localStorage.removeItem('accessToken');
        window.localStorage.removeItem('_profile');
        dispatch({
            type: 'LOGOUT_USER',
            user: null
        })
        window.location.replace('/')
    };
};
