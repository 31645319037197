import axios from 'axios';
import Swal from 'sweetalert2'
import { MODE, PROD_BASE_URL, DEV_BASE_URL } from './../../constants';


const base_url = MODE === "PROD" ? PROD_BASE_URL : DEV_BASE_URL;
const accessToken = window.localStorage.getItem('accessToken');
const configAuth = {
    headers: {
        'Accept': 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
    }
};


export const getPaymentRecords = (pageNo) => {
    return (dispatch) => {
        dispatch({
            type: 'GET_PAYMENT_RECORDS_PENDING',
            loading: true,
            error: null
        })
        return axios.get(`${base_url}/get-payment-records?page=${pageNo}`, configAuth)
            .then((response) => {
                dispatch({
                    type: 'GET_PAYMENT_RECORDS_SUCCESS',
                    loading: false,
                    data: response.data.data.data,
                    lastPage: response.data.data.last_page,
                    error: null
                })
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: 'GET_PAYMENT_RECORDS_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Fetching Payment Records Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
                return error;
            });
    };
};

export const getPaymentRecord = (refNo) => {
    return (dispatch) => {
        dispatch({
            type: 'GET_PAYMENT_RECORDS_PENDING',
            loading: true,
            error: null
        })
        return axios.get(`${base_url}/search-payment?reference=${refNo}`, configAuth)
            .then((response) => {
                dispatch({
                    type: 'GET_PAYMENT_RECORD_SUCCESS',
                    loading: false,
                    data: response.data.data,
                    error: null
                })
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: 'GET_PAYMENT_RECORD_FAILED',
                    loading: false,
                    error: error.response && error.response.data && error.response.data.error
                })
                return error;
            });
    };
};

export const initiatePlanActivation = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'INITIATE_PLAN_ACTIVATION_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/initiate-plan-activation`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'INITIATE_PLAN_ACTIVATION_SUCCESS',
                    loading: false,
                    data: response.data,
                    error: null
                })
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: 'INITIATE_PLAN_ACTIVATION_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Initiate Plan Activation Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
                return error;
            });
    };
};

export const payForPlan = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'PLAN_PAYMENT_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/plan-payment`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'PLAN_PAYMENT_SUCCESS',
                    loading: false,
                    data: response.data.data,
                    error: null
                })
                return response;
            })
            .catch((error) => {
                dispatch({
                    type: 'PLAN_PAYMENT_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Plan Payment Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
                return error;
            });
    };
};

export const confirmPayment = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'CONFIRM_PAYMENT_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/confirm-payment`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'CONFIRM_PAYMENT_SUCCESS',
                    loading: false,
                    data: response.data.data,
                    error: null
                })
                Swal.fire(
                    'Plan Payment Confirmed Successfully',
                    'You have successfully confirmed this subscription plan',
                    'success'
                ).then(() => {
                    // 
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'CONFIRM_PAYMENT_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Plan Payment Confirmation Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};

export const _reactivateSubscription = (planId) => {
    return (dispatch) => {
        dispatch({
            type: 'REACTIVATE_SUBSCRIPTION_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/activate-user-plan/${planId}`, {}, configAuth)
            .then((response) => {
                console.log(planId)
                dispatch({
                    type: 'REACTIVATE_SUBSCRIPTION_SUCCESS',
                    loading: false,
                    data: response.data.data,
                    error: null
                })
                Swal.fire(
                    'Plan Reactivated Successfully',
                    'You have successfully reactivated the subscription plan',
                    'success'
                )
            })
            .catch((error) => {
                dispatch({
                    type: 'REACTIVATE_SUBSCRIPTION_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Plan Reactivation Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};

export const _cancelSubscription = (planId) => {
    return (dispatch) => {
        dispatch({
            type: 'CANCEL_SUBSCRIPTION_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/cancel-user-subscription/${planId}`, {}, configAuth)
            .then((response) => {
                console.log(planId)
                dispatch({
                    type: 'CANCEL_SUBSCRIPTION_SUCCESS',
                    loading: false,
                    data: response.data.data,
                    error: null
                })
                Swal.fire(
                    'Plan Autorenewal Cancelled Successfully',
                    'You have successfully cancelled autorenewal for this subscription plan',
                    'success'
                )
            })
            .catch((error) => {
                dispatch({
                    type: 'CANCEL_SUBSCRIPTION_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Plan Autorenewal Cancellation Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};

export const getAutoRenewedPlansUser = (planId) => {
    return (dispatch) => {
        dispatch({
            type: 'GET_SUBSCRIPTION_PENDING',
            loading: true,
            error: null
        })
        return axios.get(`${base_url}/get-auto-renewed-plans-user/${planId}`, configAuth)
            .then((response) => {
                dispatch({
                    type: 'GET_SUBSCRIPTION_SUCCESS',
                    loading: false,
                    data: response.data.plansubscriptions,
                    error: null
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'GET_SUBSCRIPTION_FAILED',
                    loading: false,
                    error: error.response
                })
            });
    };
};


export const adminConfirmPayment = (payload) => {
    return (dispatch) => {
        dispatch({
            type: 'PLAN_PAYMENT_PENDING',
            loading: true,
            error: null
        })
        return axios.post(`${base_url}/admin-payment-confirmation`, payload, configAuth)
            .then((response) => {
                dispatch({
                    type: 'PLAN_PAYMENT_SUCCESS',
                    loading: false,
                    data: response.data.data,
                    error: null
                })
                Swal.fire(
                    'Plan Payment Confirmed Successfully',
                    'You have successfully confirmed this subscription plan',
                    'success'
                ).then(() => {
                    window.location.reload()
                })
            })
            .catch((error) => {
                dispatch({
                    type: 'PLAN_PAYMENT_FAILED',
                    loading: false,
                    error: error.response
                })
                Swal.fire(
                    'Plan Payment Confirmation Failed',
                    error.response && error.response.data && error.response.data.message,
                    'error'
                )
            });
    };
};
