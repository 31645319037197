const initialState = {
    subscriptions: [],
    error: null,
    loading: false,
    subscriptionsLoading: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'CREATE_SUBSCRIPTION_PENDING':
            return { ...state, createLoading: action.loading };
        case 'CREATE_SUBSCRIPTION_SUCCESS':
            return { ...state, createLoading: action.loading };
        case 'CREATE_SUBSCRIPTION_FAILED':
            return { ...state, createLoading: action.loading, error: action.error };

        case 'UPDATE_SUBSCRIPTION_PENDING':
            return { ...state, updateLoading: action.loading };
        case 'UPDATE_SUBSCRIPTION_SUCCESS':
            return { ...state, updateLoading: action.loading };
        case 'UPDATE_SUBSCRIPTION_FAILED':
            return { ...state, updateLoading: action.loading, error: action.error };

        case 'DELETE_SUBSCRIPTION_PENDING':
            return { ...state, deleteLoading: action.loading };
        case 'DELETE_SUBSCRIPTION_SUCCESS':
            return { ...state, deleteLoading: action.loading };
        case 'DELETE_SUBSCRIPTION_FAILED':
            return { ...state, deleteLoading: action.loading, error: action.error };

        case 'FETCH_ALL_SUBSCRIPTIONS_PENDING':
            return { ...state, subscriptionsLoading: action.loading };
        case 'FETCH_ALL_SUBSCRIPTIONS_SUCCESS':
            return { ...state, subscriptions: action.subscriptions, subscriptionsLoading: action.loading };
        case 'FETCH_ALL_SUBSCRIPTIONS_FAILED':
            return { ...state, subscriptionsLoading: action.loading, error: action.error };
        default:
            return state;
    }
};