import React from 'react'
import FormValidator from '../../utils/FormValidator';
import { sendContactMessage } from '../../redux/actions/contact';
import Swal from 'sweetalert2';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';

class Contact extends React.Component {

    constructor(props) {
        super(props);

        this.validator = new FormValidator([
            { field: "name", method: "isEmpty", validWhen: false, message: "Name cannot be empty" },
            { field: "email", method: "isEmail", validWhen: true, message: "Please provide a valid email" },
            { field: "phoneNumber", method: "isMobilePhone", args: ["en-NG"], validWhen: true, message: "Please provide a valid phone number" },
            { field: "subject", method: "isEmpty", validWhen: false, message: "Subject cannot be empty" },
            { field: "message", method: "isEmpty", validWhen: false, message: "Message cannot be empty" }
        ])

        this.state = {
            name: '',
            email: '',
            phoneNumber: '',
            subject: '',
            message: '',
            validation: this.validator.reset()
        }
    }

    _handleChange = name => event => {
        const { value } = event.target;
        this.setState({
            [name]: value
        })
    }

    _handleSubmit = event => {
        event.preventDefault();
        const { validation: _, ...fields } = this.state;
        const { sendContactMessage } = this.props;
        const validation = this.validator.validate(fields);
        if (validation.isValid) {
            sendContactMessage(fields)
                .then(() => {
                    Swal.fire('Success', 'Thanks for reaching out!', 'success');
                    this.setState({
                        name: '',
                        email: '',
                        phoneNumber: '',
                        subject: '',
                        message: '',
                    })
                })
        }
        this.setState({
            validation
        })
    }

    render() {
        const { name, email, phoneNumber, subject, message } = this.state;
        const { status } = this.props.contact;
        const isSending = status === 'PENDING'

        return (
            <div className="container py-3 p-md-3">
                <div className="row">
                    <div className="col-12 py-5 px-3 px-m-5">
                        <h3 className="text-center text-underline ml-auto mr-auto mb-1">
                            Need help or want to talk?
                        </h3>

                        <p className="mb-5 text-center ml-auto mr-auto">Reach out to us.</p>
                        <form
                            className="contact-form shadow card ml-auto mr-auto p-3 p-md-5 position-relative"
                            onSubmit={this._handleSubmit}
                        >
                            <div className={`position-absolute w-100 h-100 align-items-center justify-content-center loading-overlay`} style={{
                                display: isSending ? 'flex' : 'none'
                            }}>
                                <Loading />
                            </div>
                            <div className="form-row">
                                <div className="form-group col-12">
                                    <input
                                        className="form-control"
                                        placeholder="Your name"
                                        value={name}
                                        onChange={this._handleChange('name')}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-12 col-md-6">
                                    <input
                                        className="form-control"
                                        type="email"
                                        placeholder="Your email"
                                        value={email}
                                        onChange={this._handleChange('email')}
                                    />
                                </div>
                                <div className="form-group col-12 col-md-6">
                                    <input
                                        className="form-control"
                                        type="tel"
                                        placeholder="Your phone number"
                                        value={phoneNumber}
                                        onChange={this._handleChange('phoneNumber')}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-12">
                                    <input
                                        className="form-control"
                                        placeholder="Subject"
                                        value={subject}
                                        onChange={this._handleChange('subject')}
                                    />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-12">
                                    <textarea
                                        className="form-control no-resize"
                                        placeholder="How may we help you?"
                                        onChange={this._handleChange('message')}
                                        rows={6}
                                    >{message}</textarea>
                                </div>
                            </div>
                            <div className="form-row">
                                <button type="submit" className="btn btn-block">Get in touch</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(state => ({
    contact: state.contact
}), { sendContactMessage })(Contact);