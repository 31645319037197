import React from 'react';

const FAQ = () => {
    return (
        <article className="top-background result w-100 h-100">
			<div className="container py-3 p-md-3">
				<div className="row w-100 mx-0">
					<section className="main-body p-5 col-12">
						<div className="priceall">
							<h3 className="text-left ml-0">Ask us questions, but we just might have answered already</h3>
							<p className="text-left graytxt mb-4">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy.</p>
							<div className="accordion" id="accordionExample">
							  	<div className="card">
							    	<div className="card-header" id="headingOne">
							      		<h5 className="mb-2">
							      			<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Do we eat babies?</button>
							      		</h5>
							    	</div>
							    	<div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
							      		<div className="card-body">
							        		Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
							      		</div>
							    	</div>
							  	</div>
							  	<div className="card">
							    	<div className="card-header" id="headingTwo">
							      		<h5 className="mb-2">
							        		<button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">Do babies eat us?</button>
							      		</h5>
							    	</div>
							    	<div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
							      		<div className="card-body">
							        		Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. 
							      		</div>
							    	</div>
							  	</div>
							  	<div className="card">
							    	<div className="card-header" id="headingThree">
							      		<h5 className="mb-2">
							        		<button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">Is this really just design?</button>
							      		</h5>
							    	</div>
							    	<div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
							      		<div className="card-body">
							        		Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. 
							      		</div>
							    	</div>
							  	</div>
							  	<div className="card">
							    	<div className="card-header" id="headingFour">
							      		<h5 className="mb-2">
							      			<button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">Do we eat babies?</button>
							      		</h5>
							    	</div>
							    	<div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionExample">
							      		<div className="card-body">
							        		Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.
							      		</div>
							    	</div>
							  	</div>
							  	<div className="card">
							    	<div className="card-header" id="headingFive">
							      		<h5 className="mb-2">
							        		<button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">Do babies eat us?</button>
							      		</h5>
							    	</div>
							    	<div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionExample">
							      		<div className="card-body">
							        		Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. 
							      		</div>
							    	</div>
							  	</div>
							</div>
						</div>
					</section>
				</div>
			</div>	
		</article>
    )
}

export default FAQ;