import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as PaymentActions from '../../redux/actions/payments';
import Empty from '../../components/Empty';
import Loading from '../../components/Loading';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
const { SearchBar } = Search;


class Payments extends Component {

    state = {
        data: [],
        originalData: [],
        loading: true,
        currentPage: 1,
        paymentReference: ""
    }

    componentDidMount() {
        this.props.dispatch(PaymentActions.getPaymentRecords(1)).then(() => {
            const { payments: { data, loading } } = this.props;
            this.setState({ data, originalData: data, loading });
        });
    }

    gotoPrevPage = () => {
        this.setState({ loading: true })
        const { payments: { lastPage } } = this.props;
        const { currentPage } = this.state;
        if (currentPage > 1) {
            this.setState({ currentPage: this.state.currentPage - 1 });
            this.props.dispatch(PaymentActions.getPaymentRecords(this.state.currentPage - 1)).then(() => {
                const { payments: { data, loading } } = this.props;
                this.setState({ data, originalData: data, loading });
            });
        }
        window.scrollTo(0, 0);
    }

    gotoNextPage = () => {
        this.setState({ loading: true })
        const { payments: { lastPage } } = this.props;
        const { currentPage } = this.state;
        if (currentPage < lastPage) {
            this.setState({ currentPage: this.state.currentPage + 1 });
            this.props.dispatch(PaymentActions.getPaymentRecords(this.state.currentPage + 1)).then(() => {
                const { payments: { data, loading } } = this.props;
                this.setState({ data, originalData: data, loading });
            });
        }
        window.scrollTo(0, 0);
    }

    handleFilter = (e) => {
        e.preventDefault();
        const keyword = e.target.value;
        const { data, originalData } = this.state;

        if (keyword) {
            const result = data.filter((item) => {
                if (item.reference.includes(keyword)) return item;
            })
            this.setState({ data: result })
        } else {
            this.setState({ data: originalData })
        }
    }

    handleChange = (e) => {
        e.preventDefault();
        const paymentReference = e.target.value;
        this.setState({ paymentReference })
    }

    searchPaymentReference = (e) => {
        e.preventDefault();
        const value = this.state.paymentReference;
        this.props.dispatch(PaymentActions.getPaymentRecord(value));
    }

    activatePayment = () => {
        const { payments: { paymentRecord } } = this.props;
        this.props.dispatch(PaymentActions.adminConfirmPayment({
            reference: paymentRecord.reference
        }));
    }

    deactivatePayment = () => {
        
    }

    render() {
        const { payments: { lastPage, paymentRecord, paymentRecordError, paymentRecordLoading, updateLoading } } = this.props;
        const { data, loading, currentPage } = this.state;
        const columns = [{
            dataField: 'payment_gateway',
            text: 'Payment Gateway'
        }, {
            dataField: 'reference',
            text: 'Reference'
        }, {
            dataField: 'amount',
            text: 'Amount'
        }, {
            dataField: 'state',
            text: 'State'
        }, {
            dataField: 'user_id',
            text: 'User ID'
        }, {
            dataField: 'plan_id',
            text: 'Plan ID'
        }];
        const paginationOption = {
            custom: true,
            totalSize: data && data.length || 0
        };
        return (
            <article className="top-background result w-100 h-100">
                <div className="container py-3 p-md-3">
                    <div className="row w-100 mx-0">
                        <section className="main-body p-5s col-12" style={{ marginTop: '50px' }}>
                            <div className="priceall">
                                <div className="row w-100 mx-0 mt-4s">
                                    <form className="col-md-12" onSubmit={this.searchPaymentReference}>
                                        <div className="form-group">
                                            <label>Search Payment Reference</label>
                                            <input type="text" className="form-control" placeholder="Enter Payment Reference" onChange={this.handleChange} />
                                        </div>
                                    </form>
                                    <div className="col-md-12">
                                        {paymentRecordError && <p className="text-center">{paymentRecordError}</p>}
                                        {
                                            paymentRecord && <div>
                                                <p>Amount</p>
                                                <h4>{paymentRecord.amount}</h4>
                                                
                                                <p className="mt-3">Full Name</p>
                                                <h4>{paymentRecord.user.firstname} {paymentRecord.user.lastname}</h4>
                                                <div className="mt-4"> 
                                                    { updateLoading 
                                                        ? <button onClick={null}>Activating...</button> 
                                                        : <button onClick={this.activatePayment}>Activate Payment</button> 
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row w-100 mx-0 mt-4">
                                    <div className="col-md-12 table-responsive">
                                        {
                                            loading
                                                ? <div className="col-md-12 text-center">
                                                    <Loading />
                                                </div>
                                                : data && data.length > 0
                                                    ? <ToolkitProvider
                                                        keyField="id"
                                                        data={data}
                                                        columns={columns}
                                                        search
                                                    >
                                                        {
                                                            props => (
                                                                <div>
                                                                    <div className="row no-margin">
                                                                        <div className="col-md-8 no-padding">
                                                                            <h3 className="text-centers px-md-5s mb-3"><b>All Payments</b></h3>
                                                                        </div>
                                                                        <div className="col-md-4 no-padding">
                                                                            <SearchBar {...props.searchProps} style={{ boxShadow: 'none', width: '100%' }} placeholder="Search the table" />
                                                                        </div>
                                                                    </div>
                                                                    <BootstrapTable
                                                                        {...props.baseProps}
                                                                    />
                                                                </div>
                                                            )
                                                        }
                                                    </ToolkitProvider>
                                                    : <div className="col-md-12">
                                                        <Empty
                                                            title="Empty Payments List!"
                                                            subText="Sorry, there are no payments registered yet." />
                                                    </div>
                                        }
                                    </div>
                                    <div className="col-12 my-3 text-center">
                                        {
                                            currentPage > 1 && <button className="btn button2 mr-3" onClick={this.gotoPrevPage}>Previous Page</button>
                                        }
                                        {
                                            currentPage < lastPage && <button className="btn button2" onClick={this.gotoNextPage}>Next Page</button>
                                        }
                                    </div>
                                    <div className="col-md-12 mb-4">
                                        {currentPage === lastPage && <p className="text-center">You have reached the end of the list</p>}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </article>
        )
    }
}

export default withRouter(
    connect(state => ({
        payments: state.payments
    }))(Payments)
);