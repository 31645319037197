import React from 'react';
import { Link } from "react-router-dom";

const Navbar = ({ logout, isAuth, user_type }) => {
    const profile = JSON.parse(window.localStorage.getItem('_profile'));
    const subscriptionId = profile && profile.plan_id;
    const isLoggedIn = profile ? true : false;
    const isAdmin = profile && profile.admin_level === 1;
    console.log(profile)
    return isAuth ? (
        <header id="masthead" className="site-header shadow-lgs fixed-top">
            <div className="container px-md-5s">
                <nav className="navbar navbar-expand-lg justify-content-between navbar-light bg-light">
                    <Link to="/" className="navbar-brand pr-4 mr-lg-4 border-right" rel="home" itemprop="url">
                        <img width="98" height="42" src="../../img/elfrique-logo.png" className="custom-logo" alt="Elfrique" itemprop="logo" />
                    </Link>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-start" id="collapsibleNavbar">
                        <ul id="primary-menus" className="navbar-nav ml-auto justify-content-starts">
                            {
                                !profile
                                ? null
                                : isAdmin
                                    ? <>
                                        <li className="nav-item"><Link to="/payments/all" className="nav-link">Payments</Link></li>
                                        <li className="nav-item"><Link to="/users/all" className="nav-link">Users</Link></li>
                                        <li className="nav-item"><Link to="/subscriptions/manage" className="nav-link">Manage Subscriptions</Link></li>
                                        <li className="nav-item"><Link to="/testimonials/manage" className="nav-link">Manage Testimonials</Link></li>
                                    </>
                                    : <>
                                        <li className="nav-item ml-0 ml-lg-3"><Link to="/dashboard" className="nav-link">Dashboard</Link></li>
                                        <li className="nav-item"><Link to="/notifications" className="nav-link">Notifications</Link></li>
                                        <li className="nav-item"><Link to="/message" className="nav-link">Messages</Link></li>
                                        <li className="nav-item"><Link to="/pricing" className="nav-link">Manage Plans</Link></li>
                                        {
                                            user_type === "vendor"
                                                ? subscriptionId 
                                                    ? <li className="nav-item offset-md-2 nav-btn2"><Link to="/service/post" className="nav-link">Post Service</Link></li>
                                                    : <li className="nav-item offset-md-2 nav-btn2"><Link className="nav-link" onClick={() => alert("Only paid subscribers can post services")}>Post Service</Link></li>
                                                : user_type === "customer"
                                                    ? <li className="nav-item offset-md-2 nav-btn2"><Link to="/gig/post" className="nav-link">Post Job</Link></li>
                                                    : null
                                        }
                                    </>
                            }
                            {
                                profile && !isAdmin && <li className="nav-item profile-nav ml-0 ml-md-3"><Link to="/profile" className="nav-link"><img src="https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png" className="img-fluid img rounded-circle" /></Link></li>
                            }
                            <li className="nav-item" onClick={logout}>
                                <a className="nav-link">
                                    Logout <i className="mdi mdi-logout" />
                                </a>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    ) : (
            <header id="masthead" className="site-header shadow-lgs fixed-top">
                <div className="container px-md-5s">
                    <nav className="navbar navbar-expand-lg justify-content-betweens navbar-light bg-light">
                        <Link to="/" className="navbar-brand pr-4 mr-lg-4 border-right" rel="home" itemprop="url">
                            <img width="98" height="42" src="../../img/elfrique-logo.png" className="custom-logo" alt="Elfrique" itemprop="logo" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse justify-content-starts" id="collapsibleNavbar">
                            <ul id="primary-menus" className="navbar-nav ml-auto justify-content-ends">
                                <li className="nav-item"><Link to="/pricing" className="nav-link">Plans & Pricing</Link></li>
                                <li className="nav-item"><Link to="/testimonials" className="nav-link">Testimonials</Link></li>
                                <li className="nav-item"><Link to="/login" className="nav-link">Login</Link></li>
                                <li className="nav-item nav-btn2"><Link to="/signup" className="nav-link">Sign up</Link></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        )
}

export default Navbar;