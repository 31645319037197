import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import * as VendorActions from '../../redux/actions/vendors';
import * as ServiceActions from '../../redux/actions/services';
import moment from 'moment';
import Loading from '../../components/Loading';
import ServiceItem from '../../components/ServiceItem';
import Empty from '../../components/Empty';

class ViewVendor extends Component {

    componentDidMount() {
        let { id } = this.props.match.params;
        const payload = new FormData();
        payload.append('id', id);
        this.props.dispatch(VendorActions.fetchSingleVendor(id));
        this.props.dispatch(ServiceActions.fetchVendorServices(id));
    }

    render() {
        const { vendors: { vendor, vendorLoading } } = this.props;
        const { services: { services, servicesLoading } } = this.props;
        const profile = JSON.parse(window.localStorage.getItem('_profile'));
        const isLoggedIn =  profile ? true : false;
        return vendorLoading ? <Loading /> : vendor ? (
            <React.Fragment>
                <section className="banner-dashtop position-relative align-middle">
                    <div className="position-absolute w-100 h-100">
                        <div className="container p-md-1">
                            <div className="row col-sm-12 h-100 mx-auto flex-column justify-content-center align-items-center text-white text-center">
                                <div className="banner-top results row m-0  h-100 w-100 align-items-center justify-content-between">
                                    <div className="authorinfo row align-item-center col-12 col-md-8">
                                        <div className="h-100 col-3 rounded-circle">
                                            <div style={{ backgroundImage: 'url(https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png)' }} className="authorinfo-img img-fluid rounded-circle m-auto"></div>
                                        </div>
                                        <div className="row col-9 px-0 py-2 mx-0 flex-column">
                                            <p className="row w-100 mb-2 align-items-center px-0 mx-0">
                                                <span><a href="tel:0703 482 1034" className="orangetxt h4 font-weight-normal">{vendor && vendor.name}</a></span>
                                                <span><a href="mailto:mitchelokorie@gmail.com" className="blacktxt ml-2 small">{vendor && vendor.phone}</a></span>
                                                <span><img src="./../../img/verified-icon.png" className="img-fluid ml-3" /></span>
                                            </p>
                                            <p className="blacktxt text-left h4 font-weight-normal my-2 mx-0">{vendor && vendor.business && vendor.business.address || "No address added yet"}</p>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-4 text-center">
                                        <p className="row w-100 mb-2 align-items-center px-0 mx-0">
                                            <span><a href="#" className="ml-2"><i className=""></i></a></span>
                                            <span><a href="#" className="ml-2"><i className=""></i></a></span>
                                            <span><a href="#" className="ml-2"><i className=""></i></a></span>
                                        </p>
                                        <p className="mb-2 px-0 mx-0 h4 font-weight-normal my-1 graytxt">Edumare Caterers</p>
                                        <p className="graytxt"><small>Registered {moment(vendor && vendor.created_at).format('LL')}</small></p>
                                        {
                                            isLoggedIn &&  <Link to={`/message/${vendor && vendor.id}?new=true`} className="button messbut my-2" disabled={vendorLoading}>Send Message</Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <article className="top-background result w-100 h-100">
                    <div className="container py-3 p-md-3">
                        <div className="row justify-content-center">
                            <section className="main-body p-0 col-12">
                                <div className="banner-after pt-4 col-12 col-lg-12">
                                    <div className="row m-0 banner-txt justify-content-center h-100 col-12">
                                        <div className="row w-100 justify-content-between py-3 my-3 border-bottom">
                                            <div className="grid-title">
                                                <h3>{vendor && vendor.name}'s Services</h3>
                                            </div>
                                        </div>
                                        <div id="cardgrid" className="grid slide">
                                            <div className="">
                                                <div className="grid-item">
                                                    <div className="row flex-column align-items-start justify-content-center justify-center-md-start m-0">
                                                        {
                                                            servicesLoading
                                                                ? <div className="col-md-12 text-center">
                                                                    <Loading />
                                                                </div>
                                                                : services && services.length > 0 ? services.map((service, i) => {
                                                                    return (<ServiceItem service={service} selectService={this.selectService} isOwner={false} />)
                                                                }) : <Empty
                                                                        title="Empty Services!" subText="Sorry, there are no services added here yet" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </article>
            </React.Fragment>
        ) : "Vendor not found"
    }
}

export default withRouter(
    connect(state => ({
        vendors: state.vendors,
        services: state.services
    }))(ViewVendor)
);