import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as ServiceActions from '../../redux/actions/services';
import ServiceItem from '../../components/ServiceItem';
import Loading from '../../components/Loading';
import Empty from '../../components/Empty';

class ServiceSearchResults extends Component {

    constructor(props) {
        super(props);
        this.state = {
            payload: {
                keyword: "",
                location: ""
            }
        }
    }

    componentDidMount() {
        const { keyword } = this.props.match.params;
        if (keyword) {
            this.props.dispatch(ServiceActions.searchServices({keyword}))
        } else {
            this.props.dispatch(ServiceActions.fetchAllServices());
        }
    }

    handleChange = (propertyName, e) => {
        e.preventDefault();
        const payload = this.state.payload;
        payload[propertyName] = e.target.value;
        this.setState({ payload })
    }

    searchServices = (e) => {
        e.preventDefault();

        const { payload } = this.state;
        this.props.dispatch(ServiceActions.searchServices(payload))
    }

    render() {
        const { services: { services, servicesLoading } } = this.props;
        return (
            <React.Fragment>
                <section className="banner-result position-relative align-middle">
                    <div className="position-absolute w-100 h-100">
                        <div className="container p-md-5">
                            <div className="row col-sm-12 h-100 mx-auto flex-column justify-content-center align-items-center text-white text-center">
                                <div className="banner-searchbox results row m-0 align-items-center justify-content-center rounded">
                                    <form action="" className="form-inline align-items-center justify-content-center w-100 mx-auto px-3 rounded w-100">
                                        <div className="form-group form-row text-xs-center justify-content-xs-center w-100">
                                            <div className="col-12 col-md-4 p-1">
                                                <input type="text" onChange={this.handleChange.bind(this, "keyword")} name="searchkeys" placeholder="Enter Keyword or Phrase" className="border border-success searchword form-control" />
                                            </div>
                                            <div className="col-12 col-md-4 p-1">
                                                <input type="text" onChange={this.handleChange.bind(this, "location")} name="searchlocat" placeholder="Search Location" className="border border-success form-control rounded-0" />
                                            </div>
                                            <div className="col-12 col-md-4 p-1">
                                                <select className="form-control border border-success rounded-0">
                                                    <option>Catering & Hospitality</option>
                                                    <option>Information Technology</option>
                                                    <option>Real Estate</option>
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-4 p-1">
                                                <select className="form-control border border-success rounded-0">
                                                    <option>Availability Speed</option>
                                                    <option>Availability Speed</option>
                                                    <option>Availability Speed</option>
                                                </select>
                                            </div>
                                            <div className="col-12 col-md-4 p-1">
                                                <select className="form-control border border-success rounded-0">
                                                    <option>Cost Range</option>
                                                    <option>Cost Range</option>
                                                    <option>Cost Range</option>
                                                </select>
                                            </div>
                                            <div className="col-8 col-md-4 p-1 h-100 submt">
                                                <button onClick={this.searchServices} className="button rounded-circles" ><i className="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <article className="top-background result w-100 h-100">
                    <div className="container py-3 p-md-3">
                        <div className="row justify-content-center">
                            <section className="main-body p-0 col-9">
                                <div className="banner-after pt-4 col-12 col-lg-12 float-none float-lg-rights">

                                    <div className="row m-0 banner-txt justify-content-between h-100 col-12">

                                        <div className="row w-100 justify-content-between py-3 my-3 mx-0 border-bottom">
                                            <div className="grid-title">
                                                <h5 className="text-center text-md-left">Search results</h5>
                                            </div>
                                        </div>

                                        <div id="cardgrid" className="grid slide">
                                            <div className="">
                                                <div className="grid-item">
                                                    <div className="row flex-column align-items-start justify-content-center justify-center-md-start m-0">
                                                        {
                                                            servicesLoading
                                                                ? <div className="col-md-12 text-center">
                                                                    <Loading />
                                                                </div>
                                                                : services && services.map((service, i) => {
                                                                    return (<ServiceItem service={service} key={i} />)
                                                                }) || <Empty title="Empty Services List" 
                                                                        subText="No services found, check back later" />
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </section>

                        </div>

                    </div>

                </article>
            </React.Fragment>
        )
    }
}

export default withRouter(
    connect(state => ({
        services: state.services
    }))(ServiceSearchResults)
);