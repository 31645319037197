/**
 * The items in the `chats` array shall be objects with the following structure:
 * - contactID
 * - loadingState
 * - error
 * - messages: Array of messages, ordered by time sent/received
 *             Additionally, a flag marks each message as sent/received
 */
const initialState = {
    loadingState: 'UNLOADED',
    startingChat: false,
    sendingMessage: false,
    error: null,
    contacts: [],
    contactIDs: [],
    chats: []
};

const emptyChat = {
    loadingState: 'UNLOADED',
    error: null,
    messages: []
}

const sendMessageSuccessReducer = (state, action) => {
    let newState = {
        ...state,
        sendingMessage: false
    }
    const chat = newState.chats.find(chat => chat.contactID === action.recepientId)
    chat.messages = [
        ...chat.messages,
        {
            ...action.data,
            sent: true
        }
    ]
    return newState
}

const startNewChatSuccessReducer = (state, action) => {
    return {
        ...state,
        startingChat: false,
        contactIDs: [
            ...state.contactIDs,
            action.recpientId
        ],
        contacts: [
            ...state.contacts,
            action.recepient
        ],
        chats: [
            ...state.chats,
            {
                contactID: action.recepientId,
                ...emptyChat,
                loadingState: 'SUCCESS'
            }
        ]
    }
}

const getChat = (chats, contactID) => chats.find(chat => chat.contactID === contactID)

const fetchConversationPendingReducer = (state, action) => {
    let newState = { ...state }
    const chat = getChat(newState.chats, action.contactID)
    Object.assign(chat, {
        ...emptyChat,
        loadingState: "PENDING"
    })
    return newState
}

const fetchConversationSuccessReducer = (state, action) => {
    let newState = { ...state }
    const chat = getChat(newState.chats, action.contactID)
    const messages = [
        ...action.data.sent.map(message => ({
            ...message,
            sent: true
        })),
        ...action.data.recieved
    ]
    messages.sort((a, b) => {
        const aDate = new Date(a.created_at)
        const bDate = new Date(b.created_at)
        return aDate - bDate
    })
    Object.assign(chat, {
        loadingState: "SUCCESS",
        messages
    })
    return newState
}

const fetchConversationErrorReducer = (state, action) => {
    let newState = { ...state }
    const chat = getChat(newState.chats, action.contactID)
    Object.assign(chat, {
        ...emptyChat,
        loadingState: "ERROR",
        error: action.error
    })
    return newState
}



export default function MessagesReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_CHATS_PENDING':
            return {
                ...state,
                loadingState: 'PENDING',
                error: null,
                contacts: [],
                contactIDs: [],
                chats: []
            }
        case 'FETCH_CHATS_ERROR':
            return {
                ...state,
                loadingState: 'ERROR',
                error: action.error
            }
        case 'FETCH_CHATS_SUCCESS':
            return {
                ...state,
                loadingState: 'SUCCESS',
                contacts: action.contacts,
                contactIDs: action.contactIDs,
                chats: action.contacts.map(contact => ({
                    contactID: contact.id,
                    ...emptyChat
                }))
            }

        case 'FETCH_CONVERSATION_PENDING':
            return fetchConversationPendingReducer(state, action)
        case 'FETCH_CONVERSATION_SUCCESS':
            return fetchConversationSuccessReducer(state, action)
        case 'FETCH_CONVERSATION_ERROR':
            return fetchConversationErrorReducer(state, action)

        case 'START_NEW_CHAT_PENDING':
            return {
                ...state,
                startingChat: true
            }
        case 'START_NEW_CHAT_SUCCESS':
            return startNewChatSuccessReducer(state, action)
        case 'START_NEW_CHAT_FAILURE':
            return {
                ...state,
                startingChat: false
            }

        case 'SEND_MESSAGE_PENDING':
            return {
                ...state,
                sendingMessage: true
            }
        case 'SEND_MESSAGE_SUCCESS':
            return sendMessageSuccessReducer(state, action)
        case 'SEND_MESSAGE_ERROR':
            return {
                ...state,
                sendingMessage: false
            }

        default:
            return state;
    }
}

