import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import MainLayout from './layouts/MainLayout';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import dotenv from 'dotenv';
import configureStore from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";

const store = configureStore({});
dotenv.config();

const rootEl = document.getElementById('root');

ReactDOM.render(<Provider store={store}>
    <Router>
        <MainLayout />
    </Router>
</Provider>, rootEl);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();