import React from 'react';
import { PulseLoader} from "react-spinners";


const Loading = (props) => {
    return (
        <div className="sweet-loading row justify-content-center align-items-center" style={{height: '400px'}}>
            <PulseLoader
                size={30}
                //size={"150px"} this also works
                color={"#67B546"}
                loading={true}
            />
        </div>
    )
}

export default Loading;