import gigs from './gigs';
import users from './users';
import services from './services';
import vendors from './vendors';
import notifications from './notifications';
import messages from './messages';
import contact from './contact';
import testimonials from './testimonials';
import subscriptions from './subscriptions';
import payments from './payments';


export default {
  users, services, gigs, vendors, notifications, messages, contact, testimonials, subscriptions, payments
}