import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as GigActions from '../../redux/actions/gigs';
import GigItem from '../../components/GigItem';

class Gigs extends Component {

    state = {
        currentPage: 1
    }

    componentDidMount() {
        this.props.dispatch(GigActions.fetchAllGigs(1));
    }

    gotoPrevPage = () => {
        const { gigs: { lastPage } } = this.props;
        const { currentPage } = this.state;
        if (currentPage > 1) {
            this.setState({ currentPage: this.state.currentPage - 1 });
            this.props.dispatch(GigActions.fetchAllGigs(this.state.currentPage - 1));
        }
        window.scrollTo(0, 0);
    }

    gotoNextPage = () => {
        const { gigs: { lastPage } } = this.props;
        const { currentPage } = this.state;
        if (currentPage < lastPage ) {
            this.setState({ currentPage: this.state.currentPage + 1 });
            this.props.dispatch(GigActions.fetchAllGigs(this.state.currentPage + 1));
        }
        window.scrollTo(0, 0);
    }


    render() {
        const { gigs: { gigs, gigsLoading, lastPage } } = this.props;
        const { currentPage } = this.state;
        return (
            <main>
                <section className="banner-result position-relative align-middle">
                    <div className="position-absolute w-100 h-100">
                        <div className="container p-md-5">
                            <div className="row col-sm-12 h-100 mx-auto flex-column justify-content-center align-items-center text-white text-center">
                                <div className="banner-searchbox results row m-0 align-items-center justify-content-center rounded">
                                    <form action="" className="form-inline align-items-center justify-content-center w-100 mx-auto px-3 rounded w-100">
                                        <div className="form-group form-row text-xs-center justify-content-xs-center w-100">
                                            <div className="col-12 col-md-4 p-1">
                                              <input type="text" name="searchkeys" placeholder="Enter Keyword or Phrase" className="border border-success searchword form-control" />

                                            </div>
                                            <div className="col-12 col-md-4 p-1">
                                                <input type="text" name="searchlocat" placeholder="Search Location" className="border border-success form-control rounded-0" />
                                            </div>
                                            <div className="col-8 col-md-4 p-1 h-100 submt">
                                                <button type="submit" className="btn btn-block btn-primary rounded-circles" style={{borderRadius: '6px'}}>Search <i className="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <article className="top-background result w-100 h-100">
                    <div className="container py-3 p-md-3">
                        <div className="row justify-content-center">
                            <section className="main-body p-0 col-9">
                                <div className="banner-after pt-4 col-12 col-lg-12 float-none float-lg-right">
                                    <div className="row m-0 banner-txt justify-content-between h-100 col-12">
                                        <div className="row w-100 justify-content-between py-3 my-3 mx-0 border-bottom">
                                            <div className="grid-title">
                                                <h5 className="text-center text-md-left">All Jobs {!gigsLoading && gigs && `(${gigs.length})`}</h5>
                                            </div>
                                        </div>
                                        <div id="cardgrid" className="grid slide">
                                            <div className="">
                                                <div className="grid-item">
                                                    <div className="row flex-column align-items-start justify-content-center justify-center-md-start m-0">
                                                        {
                                                            gigsLoading 
                                                            ? 'Loading jobs...'
                                                            : gigs && gigs.map((gig, i) => {
                                                                return (<GigItem gig={gig} key={i} />)
                                                            }) || "No jobs yet"
                                                        }
                                                         <div className="col-12 my-3 text-center">
                                                            {
                                                                currentPage > 1 && <button className="btn button2 mr-3" onClick={this.gotoPrevPage}>Previous Page</button>
                                                            }
                                                            {
                                                                currentPage < lastPage && <button className="btn button2" onClick={this.gotoNextPage}>Next Page</button> 
                                                            }
                                                        </div>
                                                        <div className="col-md-12 mb-4">
                                                            {currentPage === lastPage && <p className="text-center">You have reached the end of the list</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </article>
            </main>
        )
    }
}

export default withRouter(
    connect(state => ({
      gigs: state.gigs
    }))(Gigs)
);
