const initialState = {
    vendor: null,
    vendors: [],
    error: null,
    loading: false
};

export default (state = initialState, action) => {
    switch (action.type) {

        case 'FETCH_VENDOR_PROFILE_PENDING':
            return { ...state, vendorLoading: action.loading };
        case 'FETCH_VENDOR_PROFILE_SUCCESS':
            return { ...state, vendor: action.vendor, vendorLoading: action.loading };
        case 'FETCH_VENDOR_PROFILE_FAILED':
            return { ...state, vendorLoading: action.loading, error: action.error };

        case 'LOGOUT_USER':
            return { ...state, user: action.user };
        default:
            return state;
    }
};