const normalizeResponse = response => {
    const notifications = {};
    response.forEach(notification => {
        notifications[notification.id] = notification
    })
    const allNotifications = response.map(notification => notification.id)
    const readNotifications = response
        .filter(notification => notification.read === 1)
        .map(notification => notification.id)
    const unreadNotifications = response
        .filter(notification => notification.read === 0)
        .map(notification => notification.id)
    return {
        notifications,
        allNotifications,
        readNotifications,
        unreadNotifications
    }
}

const initialState = {
    loadingState: 'UNLOADED',
    error: null,
    notifications: {},
    allNotifications: [],
    unreadNotifications: [],
    readNotifications: [],
    optimisticallyMarked: [],
}

const emptyNotifications = {
    notifications: {},
    allNotifications: [],
    unreadNotifications: [],
    readNotifications: [],
}

const markSeenOptimisticReducer = (state, action) => {
    const newState = { ...state }
    const selectedNotification = newState.notifications[action.notificationId]
    const optimisticInput = {
        id: action.notificationId,
        unreadIndex: newState.unreadNotifications.findIndex(
            notificationId => notificationId === selectedNotification.id
        )
    }
    newState.unreadNotifications = newState.unreadNotifications.filter(
        notificationId => notificationId !== selectedNotification.id
    )
    newState.readNotifications.push(action.notificationId)
    selectedNotification.read = 1
    newState.optimisticallyMarked.push(optimisticInput)
    return newState
}

const markSeenErrorReducer = (state, action) => {
    const newState = { ...state }
    const selectedNotification = newState.notifications[action.notificationId]
    const optimisticInput = newState.optimisticallyMarked.find(
        notification => notification.id === selectedNotification.id
    )
    newState.readNotifications = newState.readNotifications.filter(
        notificationId => notificationId !== selectedNotification.id
    )
    newState.unreadNotifications.splice(
        optimisticInput.unreadIndex,
        0,
        selectedNotification.id
    )
    selectedNotification.read = 0
    newState.optimisticallyMarked = state.optimisticallyMarked.filter(
        notification => notification.id !== action.notificationId
    )
    return newState
}

export default function NotificationsReducer(state = initialState, action) {
    switch (action.type) {
        case 'FETCH_NOTIFICATIONS_PENDING':
            return {
                ...state,
                loadingState: 'PENDING',
                error: null,
                ...emptyNotifications
            }
        case 'FETCH_NOTIFICATIONS_SUCCESS':
            return {
                ...state,
                loadingState: 'SUCCESS',
                ...normalizeResponse(action.data)
            }
        case 'FETCH_NOTIFICATIONS_ERROR':
            return {
                ...state,
                loadingState: 'ERROR',
                error: action.error
            }
        case 'MARK_NOTIFICATION_SEEN_OPTIMISTIC':
            return markSeenOptimisticReducer(state, action)
        case 'MARK_NOTIFICATION_SEEN_SUCCESS':
            return {
                ...state,
                optimisticallyMarked: state.optimisticallyMarked.filter(
                    notification => notification.id !== action.notificationId
                )
            }
        case 'MARK_NOTIFICATION_SEEN_ERROR':
            return markSeenErrorReducer(state, action)
        default:
            return state
    }
}