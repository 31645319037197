import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import * as ServiceActions from '../../redux/actions/services';
import * as GigActions from '../../redux/actions/gigs';
import Loading from '../../components/Loading';
import { MODE, PROD_BASE_PUBLIC_URL, DEV_BASE_PUBLIC_URL } from './../../constants';


const base_url = MODE === "PROD" ? PROD_BASE_PUBLIC_URL : DEV_BASE_PUBLIC_URL;


class Homepage extends Component {

    state = {
        payload: {
            keyword: "",
            location: ""
        }
    }

    componentDidMount() {
        this.props.dispatch(ServiceActions.fetchAllServices());
        this.props.dispatch(GigActions.fetchAllGigs());
    }

    handleChange = (propertyName, e) => {
        e.preventDefault();
        const payload = this.state.payload;
        payload[propertyName] = e.target.value;
        this.setState({ payload })
    }

    searchServices = (e) => {
        e.preventDefault();

        const { payload } = this.state;
        this.props.dispatch(ServiceActions.searchServices(payload)).then(() => {
            this.props.history.push(`/services/search/${payload.keyword}`);
        })
    }

    render() {
        const { gigs: { gigs, gigsLoading }, services: { services, servicesLoading } } = this.props;
        return (
            <main>
                <section className="banner-home position-relative align-middle">
                    <div className="position-absolute w-100 h-100">
                        <div className="container p-md-5">
                            <div className="row col-sm-12 h-100 mx-auto flex-column justify-content-center align-items-center text-white text-center">
                                <h2 className="big my-1 my-md-3">Find Capable & Efficient Vendors in Nigeria</h2>
                                <div className="banner-searchbox row m-0 align-items-center justify-content-center rounded">
                                    <form action="" className="form-inline align-items-center justify-content-center w-100 mx-auto px-3 rounded w-100">
                                        <div className="form-group form-row text-xs-center justify-content-xs-center w-100">
                                            <div className="col-12 col-md-7">
                                                <input type="text" onChange={this.handleChange.bind(this, "keyword")} name="searchkeys" placeholder="What vendor would you like to hire?" className="border-0 searchword form-control" />
                                            </div>
                                            <div className="col-12 col-md-4">
                                                <input type="text" onChange={this.handleChange.bind(this, "location")} name="searchlocat" placeholder="Find my location" className="form-control border-0 searchword" />
                                            </div>
                                            <div className="col-12 col-md-1 position-absolutes h-100 submt">
                                                <button onClick={this.searchServices} className="button" ><i className="fa fa-search"></i></button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <article className="top-background w-100 h-100">
                    <div className="container py-3 p-md-5s">
                        <div className="row">
                            <section className="main-body p-0 col-12">
                                <div className="banner-after pt-4 col-12 col-lg-12 float-none float-lg-left">
                                    <div className="row m-0 banner-txt justify-content-between h-100 col-12">
                                        <div id="cardgrid" className="grid slide">
                                            <div className="grid-label py-3 my-3 border-tops">
                                                <div className="float-left grid-title">
                                                    <h4><b>All Services {!servicesLoading && services && `(${services.length})`}</b></h4>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="grid-item">
                                                    <div className="row m-0">
                                                        {
                                                            servicesLoading 
                                                            ? <div className="col-md-12 text-center">
                                                                <Loading />
                                                            </div>
                                                            : services ? services.slice(0,6).map((service, i) => {
                                                                const media = `${base_url}/${service && service.media}`
                                                                return(<div className="col-12 col-md-4 grid-card p-0 mb-3">
                                                                    <a className="card justify-content-start border-0 m-1" href={`/service/view/${service && service.id}`}>
                                                                        <div style={{backgroundImage: `url(${media})`}} className="card-img-top rounded-top" ></div>
                                                                        <span className="badge bg-orange"><i className="fas fa-heart"></i></span>
                                                                        <div className="card-body p-3 rounded-bottom rounded">
                                                                            <h5 className="card-title">{service && service.name}</h5>
                                                                            <p className="pricerang">Budget</p>
                                                                            <p className="pricerang"><span className="p greentxt">N{service && service.budget_start}</span> - <span className="p greentxt">N{service && service.budget_end}</span></p>
                                                                        </div>
                                                                    </a>
                                                                </div>)
                                                            }) : "No service yet"
                                                        }
                                                        <div className="col-12 my-3 text-center">
                                                            <a href="/services" className="btn btn-shadow button2">View all services</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                        <div id="cardgrid" className="grid slide">
                                            <div className="grid-label py-3 my-3 border-tops">
                                                <div className="float-left grid-title">
                                                    <h4><b>All Jobs {!gigsLoading && gigs && `(${gigs.length})`}</b></h4>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="grid-item">
                                                    <div className="row m-0">
                                                        {
                                                            gigsLoading 
                                                            ? <div className="col-md-12 text-center">
                                                                <Loading />
                                                            </div>
                                                            : gigs && gigs.slice(0,6).map((gig, i) => {
                                                                const media = `${base_url}/${gig && gig.media}`
                                                                return(
                                                                    <div className="col-12 col-md-4 grid-card p-0 mb-3">
                                                                        <a className="card justify-content-start border-0 m-1" href={`/gig/view/${gig && gig.id}`}>
                                                                            <div style={{backgroundImage: `url(${media})`}} className="card-img-top rounded-top" ></div>
                                                                            <span className="badge bg-orange"><i className="fas fa-heart"></i></span>
                                                                            <div className="card-body p-3 rounded-bottom rounded">
                                                                                <h5 className="card-title">{gig && gig.name}</h5>
                                                                                <p className="pricerang">Budget</p>
                                                                                <p className="pricerang"><span className="p greentxt">N{gig && gig.budget_start}</span> - <span className="p greentxt">N{gig && gig.budget_end}</span></p>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        <div className="col-12 my-3 text-center">
                                                            <a href="/jobs" className="btn btn-shadow button2 ">View all jobs</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </article>
            </main>
        )
    }
}

export default withRouter(
    connect(state => ({
      services: state.services,
      gigs: state.gigs
    }))(Homepage)
);