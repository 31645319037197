import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ServiceItem from '../../components/ServiceItem';
import * as ServiceActions from '../../redux/actions/services';
import * as GigActions from '../../redux/actions/gigs';
import moment from 'moment';
import Loading from '../../components/Loading';
import Empty from '../../components/Empty';
import GigItem from '../../components/GigItem';


class Dashboard extends Component {

    state = {
        selectedService: null
    }

    async componentDidMount() {
        await this.props.dispatch(GigActions.fetchMyGigs());
        await this.props.dispatch(ServiceActions.fetchMyServices());
    }

    checkProfileCompletionPercentage = () => {
        const { users: { user, business } } = this.props;
        let invalid = 0, valid = 0;
        user && Object.keys(user).forEach((key) => {
            try {
                const value = user[key];
                if (!value) {
                    invalid++
                } else {
                    valid++
                }
            } catch {

            }
        })
        business && Object.keys(business).forEach((key) => {
            try {
                const value = business[key];
                if (!value) {
                    invalid++
                } else {
                    valid++
                }
            } catch {

            }
        })
        const total = invalid + valid;
        const percentage = valid / total * 100
        return percentage;
    }

    selectService = (item) => {
        this.setState({ selectedService: item })
    }

    deleteService = () => {
        const { id } = this.state.selectedService;
        this.props.dispatch(ServiceActions.deleteService(id));
    }

    deleteGig = () => {
        const { id } = this.state.selectedService;
        this.props.dispatch(GigActions.deleteGig(id));
    }

    render() {
        const { services: { services, servicesLoading, deleteLoading } } = this.props;
        const { gigs: { gigs, gigsLoading } } = this.props;
        const { users: { user, business, userLoading } } = this.props;
        const percent = this.checkProfileCompletionPercentage();
        return userLoading ? <Loading /> : (
            <article className="top-background result w-100 h-100">
                <div className="container py-3 p-md-3">
                    <div className="row">
                        <section className="main-body p-0 col-12">
                            <div className="banner-form col-12 col-lg-3 float-none float-lg-left pt-4">
                                <div className="">
                                    <div className="top-author py-4 px-3 text-center mb-3 rounded shadow">
                                        <p className="row mb-2 px-0 mx-0 h5 font-weight-normal align-items-center justify-content-center py-1 graytxts border-bottom">{user && user.name} <i className="mdi mdi-marker-check ml-2" style={{ color: "#ea9616" }} /></p>
                                        <div className="col-12 rounded-circle">
                                            <div style={{ backgroundImage: 'url(https://i.pinimg.com/originals/51/f6/fb/51f6fb256629fc755b8870c801092942.png)', backgroundPosition: 'center center' }} className="authorinfo-img img-fluid border border-white rounded-circle m-auto shadow"></div>
                                        </div>
                                        <div className="py-2 border-tops">
                                            <p><span className="orangetxt"><i className="fas fa-star"></i></span><span className="orangetxt"><i className="fas fa-star"></i></span><span className="orangetxt"><i className="fas fa-star"></i></span></p>
                                            <p className="small">Basic Plan</p>
                                        </div>
                                        <div className="py-2 border-top">
                                            {
                                                business
                                                    ? <>
                                                        <p className="small">Location</p>
                                                        <p>{`${business ? business.city : ""}, ${business ? business.state : "N/A"}`}</p>
                                                    </>
                                                    : null
                                            }
                                            <p className="small">Member since</p>
                                            <p>{moment(user && user.created_at).format('LL')}</p>
                                        </div>
                                        <div className="py-2 border-top">
                                            <p className="small">Profile setup progress {!Number.isNaN(percent) && `${percent.toFixed(0)}%`}</p>
                                            <div className="progress my-2">
                                                <div className="progress-bar bg-success" role="progressbar" style={{ width: `${percent}%` }} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <a href="/profile" className="button messbut3 my-2">Profile setup</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="banner-after pt-4 col-12 col-lg-9 float-none float-lg-right left">
                                <div className="row m-0 banner-txt justify-content-between h-100 col-12">
                                    <div className="row w-100 justify-content-between py-3 border-bottoms listing-title no-margin">
                                        <div className="col-md-12">
                                            <h4 className="no-margin"><b>Your Listings</b></h4>
                                        </div>
                                    </div>
                                    <div id="cardgrid" className="grid slide">
                                        <div className="">
                                            <div className="grid-item">
                                                <div className="row flex-column align-items-start justify-content-center justify-center-md-start m-0">
                                                    {
                                                        user && user.user_type === "vendor"
                                                            ? servicesLoading
                                                                ? <div className="col-md-12 text-center"><Loading /></div>
                                                                : services && services.length > 0 ? services.map((service, i) => {
                                                                    return (<ServiceItem service={service} selectService={this.selectService} isOwner={true} />)
                                                                }) : <Empty
                                                                        title="Empty Services!" subText="Sorry, you have no services listed yet" />
                                                            : gigsLoading
                                                                ? <div className="col-md-12 text-center"><Loading /></div>
                                                                : gigs && gigs.length > 0 ? gigs.map((gig, i) => {
                                                                    return (<GigItem selectService={this.selectService} gig={gig} isOwner={true} />)
                                                                }) : <Empty
                                                                        title="Empty Jobs!" subText="Sorry, you have no jobs listed yet" />
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="editServiceModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalCenterTitle">Delete Service</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body text-center">
                                                    <p>Are you sure you want to delete this service</p>
                                                </div>
                                                <div className="modal-footer text-center">
                                                    <button type="button" className="btn btn-secondary btn-cancel" data-dismiss="modal">No</button>
                                                    <button type="button" className="btn btn-primary btn-success" onClick={this.deleteService}>{deleteLoading ? 'Deleting' : 'Yes'}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal fade" id="editGigModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalCenterTitle">Delete Gig</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true">&times;</span>
                                                    </button>
                                                </div>
                                                <div className="modal-body text-center">
                                                    <p>Are you sure you want to delete this gig</p>
                                                </div>
                                                <div className="modal-footer text-center">
                                                    <button type="button" className="btn btn-secondary btn-cancel" data-dismiss="modal">No</button>
                                                    <button type="button" className="btn btn-primary btn-success" onClick={this.deleteGig}>{deleteLoading ? 'Deleting' : 'Yes'}</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </article>
        )
    }
}

export default withRouter(
    connect(state => ({
        users: state.users,
        services: state.services,
        gigs: state.gigs
    }))(Dashboard)
);