import axios from 'axios';
import Swal from 'sweetalert2';
import { MODE, PROD_BASE_URL, DEV_BASE_URL } from './../../constants';


const base_url = MODE === "PROD" ? PROD_BASE_URL : DEV_BASE_URL;
const accessToken = window.localStorage.getItem('accessToken');
const configAuth = {
    headers: {
        'Accept': 'multipart/form-data',
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${accessToken}`
    }
};
const authAxios = axios.create({
    ...configAuth,
    baseURL: base_url
});

export const fetchChats = (forceRefresh = false) => {
    return (dispatch, getState) => {
        const state = getState().messages
        if (state.loadingState === "SUCCESS" && !forceRefresh)
            return Promise.resolve()
        dispatch({
            type: 'FETCH_CHATS_PENDING'
        });
        return authAxios.get('/get-chat-contacts')
            .then(res => {
                const { data, status, error } = res.data
                if (status === 1)
                    dispatch({
                        type: 'FETCH_CHATS_SUCCESS',
                        contacts: data.contacts,
                        contactIDs: data.contacts_id
                    })
                else {
                    dispatch({
                        type: 'FETCH_CHATS_ERROR',
                        error
                    })
                    Swal.fire('An error occured', `Failed to load chats: ${error}`, 'error')
                }
            })
            .catch(error => {
                if (process.env.NODE_ENV === "development")
                    console.warn(error)
                dispatch({
                    type: 'FETCH_CHATS_ERROR',
                    error: error.message
                })
                Swal.fire('An error occured', `Failed to load chats: ${error.message}`, 'error')
            });
    };
};

export const fetchConversation = ({ contactID, forceRefresh = false }) => {
    return (dispatch, getState) => {
        const state = getState().messages
        if (
            state.chats.some(
                chat => chat.contactID === contactID && chat.loadingState === "SUCCESS"
            )
            && !forceRefresh
        )
            return Promise.resolve()
        dispatch({
            type: 'FETCH_CONVERSATION_PENDING',
            contactID
        })
        return authAxios.get(`/get-conversation/${contactID}`)
            .then(res => {
                const { data, status, error } = res.data
                if (status === 1)
                    dispatch({
                        type: 'FETCH_CONVERSATION_SUCCESS',
                        contactID,
                        data
                    })
                else {
                    dispatch({
                        type: 'FETCH_CONVERSATION_ERROR',
                        contactID,
                        error: error
                    })
                    Swal.fire('An error occured', `Failed to load chat: ${error}`, 'error')
                }
            })
            .catch(error => {
                if (process.env.NODE_ENV === "development")
                    console.warn(error)
                dispatch({
                    type: 'FETCH_CONVERSATION_ERROR',
                    contactID,
                    error: error.message
                })
                Swal.fire('An error occured', `Failed to load chat: ${error.message}`, 'error')
            });
    }
}

export const startNewChat = ({ recepientId }) => {
    return (dispatch, getState) => {
        const state = getState().messages
        if (state.chats.some(chat => chat.contactID === recepientId))
            return fetchConversation({ contactID: recepientId })(dispatch, getState)
        if (state.startingChat)
            return Promise.resolve()
        dispatch({
            type: 'START_NEW_CHAT_PENDING'
        })
        return authAxios.get(`/get-user-by-id/${recepientId}`)
            .then(res => {
                const { data, status, error } = res.data
                if (status === 1)
                    dispatch({
                        type: 'START_NEW_CHAT_SUCCESS',
                        recepientId,
                        recepient: data
                    })
                else {
                    dispatch({
                        type: 'START_NEW_CHAT_ERROR',
                        error: error
                    })
                    Swal.fire('An error occured', `Failed to initiate chat: ${error}`, 'error')
                }
            })
            .catch(error => {
                if (process.env.NODE_ENV === "development")
                    console.warn(error)
                dispatch({
                    type: 'START_NEW_CHAT_ERROR',
                    error: error.message
                })
                Swal.fire('An error occured', `Failed to initiate chat: ${error.message}`, 'error')
            })
    }
};

export const sendMessage = ({ recepientId, message }) => {
    return dispatch => {
        dispatch({
            type: 'SEND_MESSAGE_PENDING',
            recepientId,
            message
        })
        const fd = new FormData();
        fd.append("receiver_id", recepientId)
        fd.append("message", message)
        return authAxios.post('/send-message', fd)
            .then(res => {
                const { status, error, data } = res.data
                if (status === 1)
                    dispatch({
                        type: 'SEND_MESSAGE_SUCCESS',
                        recepientId,
                        message,
                        data
                    })
                else {
                    dispatch({
                        type: 'SEND_MESSAGE_ERROR',
                        recepientId,
                        message,
                        error
                    })
                    Swal.fire('An error occured', `Failed to send message: ${error}`, 'error')
                }
            })
            .catch(error => {
                if (process.env.NODE_ENV === "development")
                    console.warn(error)
                dispatch({
                    type: 'SEND_MESSAGE_ERROR',
                    recepientId,
                    message,
                    error: error.message
                })
                Swal.fire('An error occured', `Failed to send message: ${error.message}`, 'error')
            })
    };
};